export const containsEmojis = (value: any) =>
  !value.match(
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]+/u
  );

export const disableBrowserBackButton = () => {
  window.history.pushState(null, "", window.location.href);
  window.addEventListener("popstate", function () {
    window.history.pushState(null, "", window.location.href);
  });
};

export const specialCharRegex = /[!@#$%^&*()_+\-=`~[\]{};‘:“\\|,.<>/?]+/;

export const getLast14days = () => {
  // Get the current date
  const currentDate = new Date();
  const formatDate = (date: any) => {
    const options = { day: "numeric", month: "short" };
    return date.toLocaleDateString("en-US", options);
  };

  // Create an array to store the last 14 days
  const last14Days = [];

  // Loop 14 times to get the last 14 days
  for (let i = 13; i >= 0; i--) {
    const pastDate = new Date();
    pastDate.setDate(currentDate.getDate() - i);
    last14Days.unshift(formatDate(pastDate)); // Add to the beginning of array
  }
  return last14Days;
};


export const Users = [
  "All Users",
  "Active Users",
  "Registered Users",
  "Deactivated Users",
  "Recent Users",
];

export const Filteroptions = [
  { value: "Paid Personal Account", label: "Paid Personal Account" },
  { value: "Paid Space Account", label: "Paid Space Account" },
  { value: "Free Account", label: "Free Account" },
];
export const SortingOptions = [
  { value: "Default (No Sorting)", label: "Default (No Sorting)" },
  { value: "Name (A to Z)", label: "Name (A to Z)" },
  { value: "Name (Z to A)", label: "Name (Z to A)" },
  { value: "Date (Oldest to Newest)", label: "Date (Oldest to Newest)" },
  { value: "Date (Newest to Oldest)", label: "Date (Newest to Oldest)" },
];

export const FilteroptionsForSpace = [
  { value: "Show space with revenue", label: "Show space with revenue" },
  { value: "Show space without revenue", label: "Show space without revenue" },
];
