import React, { useState } from "react";
import colorConst from "./../../Constants/ColorConstant";
import { Card, CardHeader, CardBody } from "@nextui-org/react";
import { Box, Typography } from "@mui/material";
import { BarChart } from "./BarChart";
import fontConst from "./../../Constants/FontConstant";
import Select from "react-select";

const LoginActivityChart = (props: any) => {
  const [selected, setSelected] = useState<any>("Annually");
  const Annually = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUNE",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const Weekly = ["M", "T", "W", "T", "F", "S", "S"];
  function getArrayFromDate(date:any) {
    const dateArray = [];
    for (let i = 1; i <= date; i++) {
      dateArray.push(i);
    }
    return dateArray;
  }
  
  
  const now = new Date();
  const currentDate = now.getDate();
  const Monthly = getArrayFromDate(currentDate);
  const customStyles = {
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      backgroundColor: colorConst?.primaryGray,
      color: "black",
      border: "1px solid transparent",
      outline: "none",
      boxShadow: "gray",
      // marginTop: "7px",
      minHeight: "40px",
      width: "110px",
      borderRadius: "8px",

      "&:hover": {
        border: "1px solid transparent",
        backgroundColor: "#fff",
        color: "black",
      },
    }),

    option: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "black",
      fontSize: "16px",
      "&:hover": {
        cursor: "pointer",
        // border: "1px solid transparent",
        backgroundColor: "transparent",
        color: "gray",
      },
    }),
  };

  const Filteroptions = [
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Annually", label: "Annually" },
  ];
  const dropDownOptionsForFilter: any = Filteroptions?.map((item: any) => ({
    ...item,
    label: item?.label,
    value: item?.value,
  }));

  const handleChange = (value: any) => {
    setSelected(value?.label);
  };

  return (
    <Box >
      <Card
        shadow="none"
        style={{
          borderRadius: "6px",
          padding: "16px 16px 16px 16px",
          //   width: "max-content",
        }}
      >
        {/* //className="max-w-[400px] p-3" */}
        <CardHeader className="flex justify-between box-border gap-5">
          <Box>
            <Typography
              sx={{ fontSize: fontConst.FONTSIZE_20, fontWeight: "700" }}
            >
              {props?.title}
            </Typography>
            <Box
              mt={1}
              style={{ color: "#999999", fontSize: "14px", fontWeight: 400 }}
            >
             {props?.description}
            </Box>
          </Box>

          <Select
            components={{ IndicatorSeparator: () => null }}
            placeholder={selected}
            options={dropDownOptionsForFilter}
            // isMulti
            onChange={handleChange}
            // onChange={handleDatabasedonSearch()}
            value={selected}
            closeMenuOnSelect={true}
            styles={{
              ...customStyles,
            }}
          />
        </CardHeader>
        <CardBody >     
            {/* style={{height:"350px"}} */}
          <BarChart
            labels={selected === "Annually" ? Annually :  selected==="Monthly" ? Monthly:Weekly}
            isLogin={true}
          />
        </CardBody>
      </Card>
    </Box>
  );
};

export default LoginActivityChart;
