import React from "react";
import { Box, Grid } from "@mui/material";

import { Card, Button } from "@nextui-org/react";
import colorConst from "./../../../Constants/ColorConstant";
import fontConst from "./../../../Constants/FontConstant";
import ImageConst from "./../../../Constants/ImageConst";

const SpaceDetailsComponent = (props: any) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={3}>
      <Box mt={2}>
        <Card
          className="flex"
          style={{ padding: "23px 23px" }}
          shadow="none"
          radius="sm"
        >
          <Box
            sx={{
              position: "relative",
              borderRadius: "12px",
              width: "100%",
              height: "275px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${ImageConst?.spaceBG})`,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "#fff",
              }}
            >
              <img
                src={ImageConst?.Rukkor_logo}
                alt="Overlay"
                style={{
                  width: "80px",
                  height: "auto",
                  display: "block",
                  margin: "0 auto",
                }}
              />
              <Box
                sx={{
                  fontSize: fontConst.FONTSIZE_30,
                  fontWeight: fontConst.FONTWEIGHT_600,
                  marginTop: "20px",
                }}
              >
                Rukkor (Community)
              </Box>
            </Box>
          </Box>
          <Box mt={2}>
            <Grid container columnSpacing={2} rowSpacing={2} columns={24}>
              <Grid item xs={8}>
                <Card
                  radius="sm"
                  style={{
                    borderRadius: "14px",
                    background: "transparent",
                    border: `1px dashed ${colorConst.dashedBorder}`,
                    padding: "12px",
                    display: "flex",
                    flexDirection: "row",

                    gap: "10px",
                  }}
                  shadow="none"
                >
                  <Box
                    gap={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "max-content",
                    }}
                  >
                    <div
                      style={{
                        color: colorConst.fontBlack,
                        fontWeight: fontConst.FONTWEIGHT_400,
                        fontSize: fontConst.FONTSIZE_14,
                      }}
                    >
                      {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                      Community
                    </div>
                    <div
                      style={{
                        color: colorConst.fontGray,
                        fontWeight: fontConst.FONTWEIGHT_400,
                        fontSize: fontConst.FONTSIZE_14,
                      }}
                    >
                      Space
                    </div>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={8}>
                <Card
                  radius="sm"
                  style={{
                    borderRadius: "14px",
                    background: "transparent",
                    border: `1px dashed ${colorConst.dashedBorder}`,
                    padding: "12px",
                    display: "flex",
                    flexDirection: "row",

                    gap: "10px",
                  }}
                  shadow="none"
                >
                  <Box
                    gap={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "max-content",
                    }}
                  >
                    <div
                      style={{
                        color: colorConst.fontBlack,
                        fontWeight: fontConst.FONTWEIGHT_400,
                        fontSize: fontConst.FONTSIZE_14,
                      }}
                    >
                      {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                      English
                    </div>
                    <div
                      style={{
                        color: colorConst.fontGray,
                        fontWeight: fontConst.FONTWEIGHT_400,
                        fontSize: fontConst.FONTSIZE_14,
                      }}
                    >
                      Language
                    </div>
                  </Box>
                </Card>
              </Grid>{" "}
              <Grid item xs={8}>
                <Card
                  radius="sm"
                  style={{
                    borderRadius: "14px",
                    background: "transparent",
                    border: `1px dashed ${colorConst.dashedBorder}`,
                    padding: "12px",
                    display: "flex",
                    flexDirection: "row",

                    gap: "10px",
                  }}
                  shadow="none"
                >
                  <Box
                    gap={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "max-content",
                    }}
                  >
                    <div
                      style={{
                        color: colorConst.fontBlack,
                        fontWeight: fontConst.FONTWEIGHT_400,
                        fontSize: fontConst.FONTSIZE_14,
                      }}
                    >
                      {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                      Junior Garcia
                    </div>
                    <div
                      style={{
                        color: colorConst.fontGray,
                        fontWeight: fontConst.FONTWEIGHT_400,
                        fontSize: fontConst.FONTSIZE_14,
                      }}
                    >
                      Admin Name
                    </div>
                  </Box>
                </Card>
              </Grid>{" "}
              <Grid item xs={8}>
                <Card
                  radius="sm"
                  style={{
                    borderRadius: "14px",
                    background: "transparent",
                    border: `1px dashed ${colorConst.dashedBorder}`,
                    padding: "12px",
                    display: "flex",
                    flexDirection: "row",

                    gap: "10px",
                  }}
                  shadow="none"
                >
                  <Box
                    gap={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "max-content",
                    }}
                  >
                    <div
                      style={{
                        color: colorConst.fontBlack,
                        fontWeight: fontConst.FONTWEIGHT_400,
                        fontSize: fontConst.FONTSIZE_14,
                      }}
                    >
                      {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                      7.8k
                    </div>
                    <div
                      style={{
                        color: colorConst.fontGray,
                        fontWeight: fontConst.FONTWEIGHT_400,
                        fontSize: fontConst.FONTSIZE_14,
                      }}
                    >
                      Total Members
                    </div>
                  </Box>
                </Card>
              </Grid>{" "}
              <Grid item xs={8}>
                <Card
                  radius="sm"
                  style={{
                    borderRadius: "14px",
                    background: "transparent",
                    border: `1px dashed ${colorConst.dashedBorder}`,
                    padding: "12px",
                    display: "flex",
                    flexDirection: "row",

                    gap: "10px",
                  }}
                  shadow="none"
                >
                  <Box
                    gap={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "max-content",
                    }}
                  >
                    <div
                      style={{
                        color: colorConst.fontBlack,
                        fontWeight: fontConst.FONTWEIGHT_400,
                        fontSize: fontConst.FONTSIZE_14,
                      }}
                    >
                      {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                      #17
                    </div>
                    <div
                      style={{
                        color: colorConst.fontGray,
                        fontWeight: fontConst.FONTWEIGHT_400,
                        fontSize: fontConst.FONTSIZE_14,
                      }}
                    >
                      Channels
                    </div>
                  </Box>
                </Card>
              </Grid>{" "}
              <Grid item xs={8}>
                <Card
                  radius="sm"
                  style={{
                    borderRadius: "14px",
                    background: "transparent",
                    border: `1px dashed ${colorConst.dashedBorder}`,
                    padding: "12px",
                    display: "flex",
                    flexDirection: "row",

                    gap: "10px",
                  }}
                  shadow="none"
                >
                  <Box
                    gap={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "max-content",
                    }}
                  >
                    <div
                      style={{
                        color: colorConst.fontBlack,
                        fontWeight: fontConst.FONTWEIGHT_400,
                        fontSize: fontConst.FONTSIZE_14,
                      }}
                    >
                      {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                      Tue 19 October, 2023
                    </div>
                    <div
                      style={{
                        color: colorConst.fontGray,
                        fontWeight: fontConst.FONTWEIGHT_400,
                        fontSize: fontConst.FONTSIZE_14,
                      }}
                    >
                      Space Created Date
                    </div>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Button
              onClick={() => {
                props?.onOpen();
              }}
              disableAnimation
              disableRipple
              // variant="flat"
              style={{
                cursor: "pointer",
                color: colorConst?.Orange,
                fontSize: fontConst?.FONTSIZE_14,
                fontWeight: 400,
                width: "100%",
                height: "35px",
                borderRadius: "12px",
                border: `1px dashed ${colorConst.Orange}`,
                background: colorConst.white,
              }}
            >
              View Terms and Rules
            </Button>
          </Box>
          <Box
            sx={{
              fontSize: fontConst.FONTSIZE_14,
              fontWeight: fontConst.FONTWEIGHT_400,
              lineHeight: "21px",
            }}
            mt={2}
          >
            Lorem ipsum dolor sit amet consectetur. Non nullam lectus mollis
            pellentesque imperdiet ultrices. Eu lorem integer nulla a nec
            rhoncus at. Dignissim malesuada semper posuere turpis montes porta
            morbi sapien. Faucibus odio libero lectus nulla pretium non enim.
            Aliquet enim urna pretium molestie nascetur viverra ultricies
            pulvinar varius. Aliquam vulputate vitae purus cursus nunc neque. Ac
            interdum id felis est dis.
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default SpaceDetailsComponent;
