import React from "react";
import { Breadcrumbs, Box, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import colorConst from "./../../Constants/ColorConstant";
import fontConst from "./../../Constants/FontConstant";
import { Card, CardBody } from "@nextui-org/react";
import ImageConst from "./../../Constants/ImageConst";
import RevenueChart from "../charts/RevenueChart";
import CustomTable from "../common/CustomTable";
import { userActivities, paiUserData } from "./../../static/tableData";
import { userActivity, paidUser } from "./tables/TableHeader";
import { useAppDispatch } from "./../../store/hooks";
import {
  NavigatedFromUser,
  isUserState,
} from "./../../store/slices/adminSlice";
import { useDisclosure } from "@nextui-org/react";
import UserDetailComponent from "./UserDetails/UserDetailComponent";
import PersonalProductComponent from "./UserDetails/PersonalProductComponent";
import UserSpaceDetailComponent from "./UserDetails/UserSpaceDetailComponent";
import TnCModal from "./TnCModal/TnCModal";
import { getLast14days } from "./../../utils/functions/commonFunctions";

const UserDetails = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const dispatch = useAppDispatch();
  let getLabelsforChart = getLast14days();

  return (
    <Box mx={6}>
      <Box mt={-3}>
        <Box sx={{ padding: "40px 0px" }}>
          <Box
            mb={1}
            sx={{ fontSize: fontConst?.FONTSIZE_30, fontWeight: 700 }}
          >
            User's Details
          </Box>
          <Breadcrumbs aria-label="breadcrumb" separator={">"}>
            <Link
              to="/dashboard/users"
              onClick={() => {
                dispatch(NavigatedFromUser(false));
              }}
              style={{
                textDecoration: "none",
                color: colorConst?.Orange,
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
              Users
            </Link>
            <Link
              onClick={() => {
                dispatch(NavigatedFromUser(true));
                dispatch(isUserState("All Users"));
              }}
              to="/dashboard/users"
              style={{
                textDecoration: "none",
                color: colorConst?.Orange,
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
              All Users
            </Link>
            <Link
              to={""}
              style={{
                textDecoration: "none",
                color: colorConst.fontBlack,
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
              User Details
            </Link>
          </Breadcrumbs>
          <Box mt={3}>
            <Grid container spacing={3} columns={32}>
              <Grid
                item
                xs={26}
                sx={{ position: "relative", overflow: "visible", zIndex: 9 }}
              >
                <Card
                  radius="sm"
                  shadow="none"
                  style={{
                    padding: " 10px 30px",
                    background: "#fff",
                    position: "absolute",
                    zIndex: 99,
                    transform: "translate(-50%, 0)",

                    fontSize: fontConst.FONTSIZE_20,
                    fontWeight: 700,
                    top: "4%",
                    left: "51%",
                  }}
                >
                  Real Id
                </Card>
                <Box mt={2}>
                  <Card
                    className="flex"
                    style={{ padding: "23px 23px" }}
                    shadow="none"
                    radius="sm"
                  >
                    <Box display={"flex"} flexDirection={"row"} gap={3}>
                      <Box>
                        <img
                          src={ImageConst?.person1}
                          alt="icon"
                          style={{ width: "160px", height: "auto" }}
                        />
                      </Box>
                      <Box
                        mt={1}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={1.3}
                      >
                        <Box
                          sx={{
                            fontSize: fontConst.FONTSIZE_24,
                            fontWeight: fontConst.FONTWEIGHT_600,
                          }}
                        >
                          Junior Garcia
                        </Box>
                        <Box
                          sx={{
                            fontSize: fontConst.FONTSIZE_16,
                            fontWeight: fontConst.FONTWEIGHT_400,
                            color: colorConst?.fontGray,
                          }}
                        >
                          @Junior
                        </Box>
                        <Box mt={1}>
                          <UserDetailComponent />
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  position: "relative",
                  overflow: "visible",
                  zIndex: 9,
                  width: "100%",
                }}
              >
                <Card
                  radius="sm"
                  shadow="none"
                  style={{
                    padding: " 8px 30px",
                    background: "#fff",
                    position: "absolute",
                    zIndex: 99,
                    top: "4%",
                    left: "53%",
                    transform: "translate(-50%, 0)",
                    fontSize: fontConst.FONTSIZE_20,
                    fontWeight: 700,
                  }}
                >
                  Alias{" "}
                </Card>
                <Box mt={2}>
                  <Card
                    className="flex"
                    style={{ padding: "20px 23px" }}
                    shadow="none"
                    radius="sm"
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={1}
                      alignItems={"center"}
                    >
                      <Box>
                        <img
                          src={ImageConst?.person1}
                          alt="icon"
                          style={{ width: "160px", height: "auto" }}
                        />
                      </Box>
                      <Box
                        mt={2}
                        sx={{
                          fontSize: fontConst.FONTSIZE_24,
                          fontWeight: fontConst.FONTWEIGHT_600,
                        }}
                      >
                        Junior Garcia
                      </Box>
                      <Box
                        sx={{
                          fontSize: fontConst.FONTSIZE_16,
                          fontWeight: fontConst.FONTWEIGHT_400,
                          color: colorConst?.fontGray,
                        }}
                      >
                        @Junior
                      </Box>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <PersonalProductComponent />
          <Box mt={2}>
            <UserSpaceDetailComponent
              onOpen={() => {
                onOpen();
              }}
            />
          </Box>
          <Box mt={2}>
            <Card shadow="none" radius="sm">
              <CardBody>
                <RevenueChart
                  YAxis={["0", "4 hr", "9 hr", "14 hr", "19 hr", "24 hr"]}
                  label={getLabelsforChart}
                  title={"Usage Time Statistics"}
                  description={"Past 14 days of usage time per days"}
                  isDisplayRightSide={false}
                />
              </CardBody>
            </Card>
          </Box>
          <Box mt={2} mr={4}>
            <CustomTable
              title={"Users Activity"}
              description={"you can see the activityfor the all users"}
              isType="3"
              hasSearch={true}
              userTableData={userActivities}
              Users={userActivity}
            />{" "}
          </Box>
          <Box mt={2} mr={4}>
            <Grid container columns={32} columnSpacing={7}>
              <Grid item xs={16}>
                <CustomTable
                  title={"Invoices"}
                  description={"You can see the all invoices"}
                  isType="3"
                  userTableData={paiUserData}
                  Users={paidUser}
                  hasSearch={true}
                />
              </Grid>
              <Grid item xs={16}>
                <CustomTable
                  title={"Users Activity"}
                  description={"you can see the activityfor the all users"}
                  isType="3"
                  hasSearch={true}
                  userTableData={userActivities}
                  Users={userActivity}
                />{" "}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <TnCModal
        onOpenChange={() => {
          onOpenChange();
        }}
        isOpen={isOpen}
      />
    </Box>
  );
};

export default UserDetails;
