import React from "react";
import { Box, Grid } from "@mui/material";

import { Card, CardHeader, CardBody, Chip } from "@nextui-org/react";
import colorConst from "./../../../Constants/ColorConstant";
import fontConst from "./../../../Constants/FontConstant";
const PersonalProductComponent = () => {
  return (
    <div>
      {" "}
      <Box mt={2}>
        <Card shadow="none" radius="sm" style={{ padding: "23px " }}>
          <CardHeader>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <Box
                sx={{
                  color: colorConst.fontBlack,
                  fontWeight: fontConst.FONTWEIGHT_700,
                  fontSize: fontConst.FONTSIZE_20,
                }}
              >
                Personal Product
              </Box>
              <Box
                sx={{
                  color: colorConst.fontGray,
                  fontWeight: fontConst.FONTWEIGHT_400,
                  fontSize: fontConst.FONTSIZE_14,
                }}
              >
                Junior has paid for below products and get license.{" "}
              </Box>
            </Box>
          </CardHeader>
          <CardBody>
            <Box mr={2} ml={-1}>
              <Grid container columns={24} columnSpacing={2} rowSpacing={2}>
                {[1, 2, 3]?.map((i: any) => {
                  return (
                    <Grid item xs={5.3} key={i}>
                      <Card
                        radius="sm"
                        style={{
                          borderRadius: "14px",
                          background: "transparent",
                          border: `3px solid ${colorConst.primaryGray}`,
                          padding: "15px 13px",
                          // display: "flex",
                          // flexDirection: "row",
                          // gap:'75px'
                        }}
                        shadow="none"
                      >
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Box
                            width={"80%"}
                            gap={1.3}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              // width: "max-content",
                            }}
                          >
                            <div
                              style={{
                                color: colorConst.fontBlack,
                                fontWeight: fontConst.FONTWEIGHT_400,
                                fontSize: fontConst.FONTSIZE_16,
                              }}
                            >
                              KanBan
                            </div>
                            <div
                              style={{
                                color: colorConst.fontGray,
                                fontWeight: fontConst.FONTWEIGHT_500,
                                fontSize: fontConst.FONTSIZE_14,
                              }}
                            >
                              € 40 / mo
                            </div>
                            <div
                              style={{
                                color: colorConst.fontGray,
                                fontWeight: fontConst.FONTWEIGHT_400,
                                fontSize: fontConst.FONTSIZE_14,
                              }}
                            >
                              Mon 14 November 2022
                            </div>
                          </Box>

                          <Box mr={0}>
                            <Chip
                              radius="sm"
                              style={{
                                color: "#1B84FF",
                                background: "rgba(27, 132, 255, 0.15)",
                              }}
                            >
                              Licensed
                            </Chip>{" "}
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </CardBody>
        </Card>
      </Box>
    </div>
  );
};

export default PersonalProductComponent;
