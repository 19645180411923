import React, { useEffect,useState } from "react";
import { Breadcrumbs, Box, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  selectSpaceState,
  isSpaceState,
  NavigatedFromSpace,
  isSpaceData,
  selectSpaceData,
} from "./../../../store/slices/adminSlice";
import { useAppDispatch } from "./../../../store/hooks";
import { Tabs, Tab, Chip } from "@nextui-org/react";
import { useAppSelector } from "./../../../store/hooks";
import colorConst from "./../../../Constants/ColorConstant";
import CustomTable from "./../../../Components/common/CustomTable";
import { items3 } from "./../../../static/tableData";
import { spaceTableHeader } from "./TableHeader";
import Select from "react-select";
import ImageConst from "./../../../Constants/ImageConst";
import Checkbox from "@mui/material/Checkbox";
import {
  FilteroptionsForSpace,
  
} from "./../../../utils/functions/commonFunctions";
import SortButton from "./../../../Components/common/SortButton";
// import {
//   Dropdown,
//   DropdownTrigger,
//   DropdownMenu,
//   DropdownItem,
// } from "@nextui-org/react";
const AllSpacesTable = () => {
  const isActivespaceState: any = useAppSelector(selectSpaceState);
  let userTableData: any = useAppSelector(selectSpaceData);
  const customStyles = {
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "black",
      border: "1px solid transparent",
      outline: "none",
      boxShadow: "gray",
      // marginTop: "7px",
      minHeight: "40px",
      width: "280px",
      borderRadius: "8px",

      "&:hover": {
        border: "1px solid transparent",
        backgroundColor: "#fff",
        color: "black",
      },
    }),

    option: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "black",
      fontSize: "16px",
      "&:hover": {
        cursor:"pointer",
        // border: "1px solid transparent",
        backgroundColor: "transparent",
        color: "gray",
      },
    }),
  };
  const [filterOption, setFilterOption] = useState([]);
  const [selectedKeys,setSelectedKeys] = useState<any>("Default (No Sorting)");

const navigate= useNavigate()
  const dispatch = useAppDispatch();
  const Users = [
    "All Space",
    "Work Space",
    "Community Space",
    "Archived Space",
    "Newly Created Space",
  ];
  const dropDownOptionsForFilter: any = FilteroptionsForSpace?.map((item: any) => ({
    ...item,
    label: item.label,
    value: item.value,
  }));
  const customOptionsForFilter = dropDownOptionsForFilter?.map(
    (option: any) => {
      const isChecked = filterOption?.some(
        (item: any) => item?.value === option.value
      );
      return {
        ...option,
        label: (
          <Box sx={{ maxHeight: "30px" }}>
            <Checkbox
             disableFocusRipple
             disableRipple
              checked={isChecked}
              onChange={() => handleCheckboxChange(option)}
              sx={{
                color: colorConst?.borderGray,
                "&.Mui-checked": {
                  color: colorConst?.Orange,
                },
              }}
            />
            <span>{option.label}</span>
          </Box>
        ),
      };
    }
  );
  const handleCheckboxChange = (option: any) => {
    const isChecked = filterOption?.some(
      (item: any) => item?.value === option?.value
    );
    setFilterOption((prevSelectedOptions: any) => {
      if (isChecked) {
        return prevSelectedOptions.filter(
          (item: any) => item?.value !== option?.value
        );
      } else {
        return [...prevSelectedOptions, option];
      }
    });
  };

  const handleClick:any=(prop:any)=>{
    navigate('/dashboard/spaces/space-details')
    
    }
  // const handleDatabasedonSearch = useCallback(() => {
  //   if (filterOption?.length > 0) {
  //     const filtered = userTableData?.filter((item: any) => {
  //       return filterOption?.some(
  //         (option: any) => option?.value === item?.category
  //       );
  //     });
  //     return userTableData = filtered;
  //   } else {
  //     return userTableData;
  //   }
  // }, [filterOption, isActivespaceState, userTableData]);
  useEffect(() => {
    const fetchUserData = (state: any) => {
      switch (state) {
        case "All Space":
          dispatch(isSpaceData(items3));
          break;
        case "Work Space":
          dispatch(isSpaceData(items3));
          break;
        case "Community Space":
          dispatch(isSpaceData([]));
          break;
        case "Archived Space":
          dispatch(isSpaceData([]));
          break;
        default:
          dispatch(isSpaceData(items3));
      }
      return;
    };
    fetchUserData(isActivespaceState);
  }, [isActivespaceState, dispatch]);
  return (
    <Box sx={{ fontFamily: "roboto" }} mr={4}>
      <Box display={"flex"} flexDirection={"row"}>
        <Grid container columns={16}>
          <Grid item xs={13}>
            <Box>
            
              <Box sx={{ padding: "30px 0px" }}>
                <Breadcrumbs aria-label="breadcrumb" separator={">"}>
                  <Link
                    to=""
                    onClick={() => {
                      dispatch(NavigatedFromSpace(false));
                    }}
                    style={{
                      textDecoration: "none",
                      color: colorConst?.Orange,
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    Spaces
                  </Link>
                  <Link
                    to={""}
                    style={{
                      textDecoration: "none",
                      color: colorConst.fontBlack,
                      fontWeight: 400,
                fontSize: "14px",
                    }}
                  >
                    {isActivespaceState}
                  </Link>
                </Breadcrumbs>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2.3}>
            <Box
              gap={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "30px 0px",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ color: colorConst.fontGray }}
              >
                Category
              </Box>
              <Box>
                <Select
                  options={customOptionsForFilter}
                  // isMulti
                  // onChange={handleChange}
                  // onChange={handleDatabasedonSearch()}
                  value={filterOption}
                  closeMenuOnSelect={false}
                  styles={{
                    ...customStyles,
                  }}
                />
              </Box>
              <Box>
                <img src={ImageConst?.search} alt="search" />
              </Box>
              <Box>
              <SortButton selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys}/>
              </Box>
             
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={-3}>
        <Tabs
          key={"bordered"}
          variant={"solid"}
          aria-label="Tabs variants"
          onSelectionChange={(e) => {
            dispatch(isSpaceState(e));
          }}
          defaultSelectedKey={isActivespaceState}
        >
          {Users?.map((user: any) => {
            return (
              <Tab
                key={user}
                title={
                  <div className="flex items-center gap-2">
                    <span>{user}</span>
                    <Chip
                      size="sm"
                      style={{
                        background: colorConst?.Orange,
                        color: colorConst?.white,
                        display: isActivespaceState === user ? "flex" : "none",
                      }}
                    >
                      94.5k
                    </Chip>
                  </div>
                }
                style={{
                  color:
                    user === isActivespaceState
                      ? colorConst?.fontBlack
                      : colorConst.fontGray,
                  fontSize: "16px",
                  fontWeight: 500,
                  fontFamily: "roboto",
                  width: isActivespaceState === user ? "210px" : "150px",
                  height: "54px",
                  background: user === isActivespaceState ? "#fff" : " #f4f4f4",
                  borderRadius: "14px 14px 0px 0px",
                  border: "1px solid transparent",
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box mt={-0.5}>
        <CustomTable userTableData={userTableData} Users={spaceTableHeader}   onClick={handleClick}/>
      </Box>
    </Box>
  );
};

export default AllSpacesTable;
