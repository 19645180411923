import React from "react";
import { Box, Grid } from "@mui/material";

import { Card, CardHeader, CardBody, CardFooter } from "@nextui-org/react";
import colorConst from "./../../../Constants/ColorConstant";
import fontConst from "./../../../Constants/FontConstant";
const SpaceProductsComponent = () => {
  return (
    <Box>
      {" "}
      <Card
        className="flex"
        style={{ padding: "20px 23px" }}
        shadow="none"
        radius="sm"
      >
        <CardHeader>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Box
              sx={{
                fontSize: fontConst.FONTSIZE_20,
                fontWeight: fontConst.FONTWEIGHT_700,
                color: colorConst.fontBlack,
              }}
            >
              Space Products
            </Box>
            <Box
              sx={{
                fontSize: fontConst.FONTSIZE_14,
                fontWeight: fontConst.FONTWEIGHT_400,
                color: colorConst.fontGray,
              }}
            >
              Below products are purchased and get license.
            </Box>
          </Box>
        </CardHeader>
        <CardBody>
          <Box mr={2} ml={-1}>
            <Grid container columns={24} columnSpacing={2} rowSpacing={2}>
              {[1, 2, 3, 4, 5]?.map((i: any) => {
                return (
                  <Grid item xs={12} key={i}>
                    <Card
                      radius="sm"
                      style={{
                        borderRadius: "14px",
                        background: "transparent",
                        border: `3px solid ${colorConst.primaryGray}`,
                        padding: "15px 13px",
                        // display: "flex",
                        // flexDirection: "row",
                        // gap:'75px'
                      }}
                      shadow="none"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Box
                          width={"80%"}
                          gap={1.3}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            // width: "max-content",
                          }}
                        >
                          <div
                            style={{
                              color: colorConst.fontBlack,
                              fontWeight: fontConst.FONTWEIGHT_400,
                              fontSize: fontConst.FONTSIZE_16,
                            }}
                          >
                            KanBan
                          </div>
                          <div
                            style={{
                              color: colorConst.fontGray,
                              fontWeight: fontConst.FONTWEIGHT_500,
                              fontSize: fontConst.FONTSIZE_14,
                            }}
                          >
                            € 40 / mo
                          </div>
                          <div
                            style={{
                              color: colorConst.fontGray,
                              fontWeight: fontConst.FONTWEIGHT_400,
                              fontSize: fontConst.FONTSIZE_14,
                            }}
                          >
                            Mon 14 November 2022
                          </div>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </CardBody>
        <CardFooter>
          <Box
            mr={2}
            sx={{
              background: colorConst.primaryGray,
              color: colorConst.fontBlack,
              width: "100%",
              padding: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "14px",
              fontWeight: fontConst.FONTWEIGHT_400,
              fontSize: fontConst.FONTSIZE_16,
            }}
          >
            Total Revenue: € 8013
          </Box>
        </CardFooter>
      </Card>
    </Box>
  );
};

export default SpaceProductsComponent;
