import React, { useEffect, useState } from "react";
import { Breadcrumbs, Box, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import {
  NavigatedFromUser,
  selectUserState,
  isUserState,
  isUserData,
  selectUserData,
} from "./../../../store/slices/adminSlice";

import {
  Users,
  Filteroptions,
} from "./../../../utils/functions/commonFunctions";
import { useAppDispatch } from "./../../../store/hooks";
import { Tabs, Tab } from "@nextui-org/react";
import { useAppSelector } from "./../../../store/hooks";
import { Chip } from "@nextui-org/react";
import { items, items2 } from "./../../../static/tableData";
import CustomTable from "./../../../Components/common/CustomTable";
import { userTableHeader } from "./TableHeader";
import Select from "react-select";
import colorConst from "./../../../Constants/ColorConstant";
import ImageConst from "./../../../Constants/ImageConst";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import SortButton from "./../../../Components/common/SortButton";

const AllUsersTable = () => {
  const isActiveuserState: any = useAppSelector(selectUserState);
  let userTableData: any = useAppSelector(selectUserData);
  const [selectedKeys,setSelectedKeys] = useState<any>("Default (No Sorting)");
  const [filterOption, setFilterOption] = useState([]);
  const dispatch = useAppDispatch();
  const navigate=useNavigate()

  const customStyles = {
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "black",
      border: "1px solid transparent",
      outline: "none",
      boxShadow: "gray",
      // marginTop: "7px",
      minHeight: "40px",
      width: "250px",
      borderRadius: "8px",

      "&:hover": {
        border: "1px solid transparent",
        backgroundColor: "#fff",
        color: "black",
      },
    }),

    option: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "black",
      fontSize: "16px",
      "&:hover": {
        cursor: "pointer",
        // border: "1px solid transparent",
        backgroundColor: "transparent",
        color: "gray",
      },
    }),
  };

  const dropDownOptionsForFilter: any = Filteroptions?.map((item: any) => ({
    ...item,
    label: item.label,
    value: item.value,
  }));


  const customOptionsForFilter = dropDownOptionsForFilter?.map(
    (option: any) => {
      const isChecked = filterOption?.some(
        (item: any) => item?.value === option.value
      );
      return {
        ...option,
        label: (
          <Box sx={{ maxHeight: "30px" }}>
            <Checkbox
              disableFocusRipple
              disableRipple
              checked={isChecked}
              onChange={() => handleCheckboxChange(option)}
              sx={{
                color: colorConst?.borderGray,

                "&.Mui-checked": {
                  color: colorConst?.Orange,
                },
              }}
            />
            <span>{option.label}</span>
          </Box>
        ),
      };
    }
  );
  const handleCheckboxChange = (option: any) => {
    const isChecked = filterOption?.some(
      (item: any) => item?.value === option?.value
    );
    setFilterOption((prevSelectedOptions: any) => {
      if (isChecked) {
        return prevSelectedOptions.filter(
          (item: any) => item?.value !== option?.value
        );
      } else {
        return [...prevSelectedOptions, option];
      }
    });
  };

  const handleClick:any=(prop:any)=>{
    navigate('/dashboard/users/user-details')
    
    }

  // const handleDatabasedonSearch = useCallback(() => {
  //   if (filterOption?.length > 0) {
  //     const filtered = userTableData?.filter((item: any) => {
  //       return filterOption?.some(
  //         (option: any) => option?.value === item?.category
  //       );
  //     });
  //     return userTableData = filtered;
  //   } else {
  //     return userTableData;
  //   }
  //   return userTableData;
  // }, [filterOption, isActiveuserState, userTableData]);

  useEffect(() => {
    const fetchUserData = (state: any) => {
      switch (state) {
        case "All Users":
          dispatch(isUserData(items));
          break;
        case "Active Users":
          dispatch(isUserData(items2));
          break;
        case "Registered Users":
          dispatch(isUserData([]));
          break;
        case "Deactivated Users":
          dispatch(isUserData([]));
          break;
        default:
          dispatch(isUserData(items));
      }
      return;
    };
    fetchUserData(isActiveuserState);
  }, [isActiveuserState, dispatch]);
  
  return (
    <Box sx={{ fontFamily: "roboto" }}  mr={4}>
      <Box display={"flex"} flexDirection={"row"}>
        <Grid container columns={16}>
          <Grid item xs={12.5}>
            <Box>
              <Box sx={{ padding: "30px 0px" }}>
                <Breadcrumbs aria-label="breadcrumb" separator={">"}>
                  <Link
                    to=""
                    onClick={() => {
                      dispatch(NavigatedFromUser(false));
                    }}
                    style={{
                      textDecoration: "none",
                      color: colorConst?.Orange,
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    User
                  </Link>
                  <Link
                    to={""}
                    style={{
                      textDecoration: "none",
                      color: colorConst.fontBlack,
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    {isActiveuserState}
                  </Link>
                </Breadcrumbs>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3.5}>
            <Box
              gap={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "30px 0px",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ color: colorConst.fontGray }}
              >
                Category
              </Box>
              <Box>
                <Select
                  options={customOptionsForFilter}
                  // isMulti
                  // onChange={handleChange}
                  // onChange={handleDatabasedonSearch()}
                  value={filterOption}
                  closeMenuOnSelect={false}
                  styles={{
                    ...customStyles,
                  }}
                />
              </Box>
              <Box>
                <img src={ImageConst?.search} alt="search" />
              </Box>
              <Box>
               <SortButton selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys}/>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={-3}>
        <Tabs
          key={"bordered"}
          variant={"solid"}
          aria-label="Tabs variants"
          onSelectionChange={(e) => {
            // fetchUserData(e);
            dispatch(isUserState(e));
          }}
          defaultSelectedKey={isActiveuserState}
        >
          {Users?.map((user: any) => {
            return (
              <Tab
                key={user}
                title={
                  <div className="flex items-center gap-2">
                    <span>{user}</span>
                    <Chip
                      size="sm"
                      style={{
                        background: colorConst?.Orange,
                        color: colorConst?.white,
                        display: isActiveuserState === user ? "flex" : "none",
                      }}
                    >
                      94.5k
                    </Chip>
                  </div>
                }
                style={{
                  color:
                    user === isActiveuserState
                      ? colorConst?.fontBlack
                      : colorConst.fontGray,
                  fontSize: "16px",
                  fontWeight: 500,
                  fontFamily: "roboto",
                  width: isActiveuserState === user ? "190px" : "150px",
                  height: "54px",
                  background: user === isActiveuserState ? "#fff" : " #f4f4f4",
                  // borderRadius: "14px 14px 0px 0px",
                  border: "1px solid transparent",
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box mt={-0.5}>
        <CustomTable userTableData={userTableData} Users={userTableHeader} onClick={handleClick} />
      </Box>
    </Box>
  );
};

export default AllUsersTable;
