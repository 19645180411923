import React from "react";

import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
 
  User,
  
} from "@nextui-org/react";
import { items3 } from "./../../static/tableData";
import { Box, Grid } from "@mui/material";

import ImageConst from "./../../Constants/ImageConst";
export default function CardHolder() {
  // const [selected, setSelected] = useState<any>("annually");
  const columns = ["name", "date", "age"];
  // const renderCell = React.useCallback((user: any, columnKey: any) => {
  //   const cellValue = user[columnKey];
  // }, []);

  const renderCell = (props: any, data: any) => {

    switch (props) {
      case "email":
        return (
          <>
            <img src={ImageConst?.email} alt="on"/>
            {data?.admin?.name}
          </>
        );
      case "user":
        return (
          <User
            avatarProps={{ radius: "lg", src: ImageConst?.peer }}
            description={data?.admin?.name}
            name={data?.admin?.name}
          >
            {data?.admin?.des}{" "}
          </User>
        );
    }
  };

  return (
    <Grid style={{ padding: "20px 40px" }} container spacing={2}>
      <Grid item xs={4}>
        <Table
          
          aria-label="Example static collection table"
          topContent={
            <Grid container columns={12}>
              <Grid item xs={8} md={8}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    my: "10px",
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "20px",
                      fontWeight: 700,
                      fontFamily: "roboto",
                    }}
                  >
                    vdbvvbbdb
                  </Box>
                  <Box
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      fontFamily: "roboto",
                      // color: colorConst.fontGray,
                    }}
                  >
                    dfvdfvdfvdfvfd
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4} md={4}>
                <Box mt={2}></Box>
                <Box></Box>
              </Grid>
            </Grid>
          }
          hideHeader={false}
          bottomContent={
            <div className="flex w-full justify-end">{"fvfdbvfbdd"}</div>
          }
        >
          <TableHeader>
            {columns?.map((i: any) => {
              return <TableColumn key={i}>{i}</TableColumn>;
            })}
          </TableHeader>
          <TableBody emptyContent={"No rows to display."}>
            {items3?.map((i: any) => {
              return (
                <TableRow
                  key={i?.revenue}
                  onClick={() => {
                    alert(`${i?.admin?.name}`);
                  }}
                >
                  <TableCell>{renderCell("email", i)}</TableCell>
                  <TableCell> {renderCell("user", i)}</TableCell>
                  <TableCell>{i?.revenue}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
