import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";

import ImageConst from "./../../Constants/ImageConst";
import ColorConstant from "./../../Constants//ColorConstant";
import Statistics from "./../common/Statistics";
import { useAppSelector } from "./../../store/hooks";
import AllUsersTable from "./tables/AllUsersTable";
import { useAppDispatch } from "./../../store/hooks";
import CustomTable from "./../common/CustomTable";
import {
  selectNavigatedFromUser,
  NavigatedFromSpace,
  isUserState,
  NavigatedFromUser,
} from "./../../store/slices/adminSlice";
import RevenueChart from "./../charts/RevenueChart";
import { recentUsers, userActivityheader } from "./tables/TableHeader";
import { items5, items7 } from "./../../static/tableData";
import LoginActivityChart from "../charts/LoginActivityChart";
import { useNavigate } from "react-router-dom";
const Users = () => {
  const isNavigatredfromDashboard: any = useAppSelector(
    selectNavigatedFromUser
  );
  const navigate= useNavigate()
  const dispatch = useAppDispatch();
  const handleClick:any=(prop:any)=>{
    navigate('/dashboard/users/user-details')
    
    }

  useEffect(() => {
    dispatch(NavigatedFromSpace(null));
  }, [dispatch]);
  return (
    <Box mx={6}>
      {isNavigatredfromDashboard === true ? (
        <Box mt={-3}>
        <AllUsersTable />
        </Box>
      ) : (
        <Box mt={2}>
          <Grid container spacing={3} columns={32}>
            <Grid
              item
              xs={8}
              onClick={() => {
                dispatch(isUserState("All Users"));
                dispatch(NavigatedFromUser(true));
              }}
            >
              <Box>
                <Statistics
                  title={"All Users"}
                  count={"5.3k"}
                  icon={ImageConst?.user_blue}
                  color={ColorConstant?.lightBlue}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              onClick={() => {
                dispatch(isUserState("Active Users"));
                dispatch(NavigatedFromUser(true));
              }}
            >
              <Box>
                <Statistics
                  title={"Active Users"}
                  count={"3.5k"}
                  icon={ImageConst?.user_green}
                  color={ColorConstant?.lightGreen}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              onClick={() => {
                dispatch(isUserState("Registered Users"));
                dispatch(NavigatedFromUser(true));
              }}
            >
              <Box>
                <Statistics
                  title={"Registered Users"}
                  count={50}
                  icon={ImageConst?.user_brown}
                  color={ColorConstant?.lightBrown}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              onClick={() => {
                dispatch(isUserState("Deactivated Users"));
                dispatch(NavigatedFromUser(true));
              }}
            >
              <Box>
                <Statistics
                  title={"Deactivate Users"}
                  count={"400"}
                  icon={ImageConst?.user_gray}
                  color={ColorConstant?.lightGray}
                />
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Box mt={3} mr={4}>
              <Grid
                container
                columns={32}
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
              >
                <Grid item xs={21.5}>
                  <Box display={"flex"} flexDirection={"column"} gap={3}>
                    <Box>
                      <RevenueChart
                        title={"User Statistics"}
                        description={
                          "This is the all about new registered users"
                        }
                        isDisplayRightSide={true}

                      />
                    </Box>
                    <Box>
                      <Grid item xs={30} sm={29.5} md={30} lg={30.7} xl={31}>
                        <CustomTable
                          title={"Users Activity"}
                          description={
                            "you can see the activityfor the all users"
                          }
                          isType="3"
                          hasSearch={true}
                          userTableData={items7}
                          Users={userActivityheader}
                          onClick={handleClick}

                        />
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={10.5}>
                  <Box display={"flex"} flexDirection={"column"} gap={3}>
                    <Box>
                      <CustomTable
                        isType={"2"}
                        title={"Recent Users"}
                        userTableData={items5}
                        Users={recentUsers}
                        onClick={handleClick}

                      />
                    </Box>
                    <Box mr={-3.7}>
                      <LoginActivityChart title={"Login Activity"} />
                    </Box>
                  </Box>

                  {/* <SpaceChart /> */}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Users;
