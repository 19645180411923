import React from "react";
import { Box } from "@mui/material";
import ImageConst from "./../../../Constants/ImageConst";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Divider,
} from "@nextui-org/react";
import fontConst from "./../../../Constants/FontConstant";
import { terms } from "./../../../static/TnC/TnC";

const TnCModal = (props: any) => {
  return (
    <div>
      {" "}
      <Modal
        size="3xl"
        closeButton={
          <Box mt={1} mr={1}>
            <img src={ImageConst?.close} alt="close" onClick={() => {}} />
          </Box>
        }
        backdrop="opaque"
        isOpen={props?.isOpen}
        onOpenChange={() => {
          props?.onOpenChange();
        }}
        motionProps={{
          variants: {
            enter: {
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.3,
                ease: "easeOut",
              },
            },
            exit: {
              y: -20,
              opacity: 0,
              transition: {
                duration: 0.2,
                ease: "easeIn",
              },
            },
          },
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader
                className="flex flex-col gap-1"
                style={{ margin: "10px 0px 0px 0px" }}
              >
                <Box
                  sx={{
                    fontSize: fontConst.FONTSIZE_24,
                    fontWeight: fontConst.FONTWEIGHT_400,
                  }}
                >
                  {" "}
                  Terms and Rules
                </Box>
              </ModalHeader>
              <Divider />
              <ModalBody style={{ padding: "20px 30px" }}>
                <Box
                  sx={{
                    fontSize: fontConst.FONTSIZE_14,
                    fontWeight: fontConst.FONTWEIGHT_400,
                    lineHeight: "21px",
                  }}
                >
                  The Rukkor (Community) space is a place where people can come
                  together to discuss all things Rukkor related. It’s an open
                  community and the official employees of Rukkor are admins and
                  moderators.
                </Box>
                <p
                  style={{
                    fontSize: fontConst.FONTSIZE_16,
                    fontWeight: fontConst.FONTWEIGHT_700,
                    lineHeight: "24px",
                  }}
                >
                  Code of Conduct
                </p>
                <Box>
                  {terms?.map((i: any, index: any) => {
                    return (
                      <Box key={index}>
                        <Box
                          mb={2}
                          sx={{
                            fontSize: fontConst.FONTSIZE_14,
                            fontWeight: fontConst.FONTWEIGHT_400,
                            lineHeight: "21px",
                          }}
                        >
                          {index + 1}. {i}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    fontSize: fontConst.FONTSIZE_14,
                    fontWeight: fontConst.FONTWEIGHT_400,
                    lineHeight: "21px",
                  }}
                >
                  Remember, the goal of this code of conduct is to create a safe
                  and welcoming environment for all members of the chat
                  community. If you notice any violations of the code of
                  conduct, please report them to a moderator or administrator.
                </Box>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default TnCModal;
