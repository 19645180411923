export const items = [
  {
    user: "John Doe",
    email: "john.doe@example.com",
    phone: "+1234567890",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Paid Space Account",
  },
  {
    user: "Jane Smith",
    email: "jane.smith@example.com",
    phone: "+1987654321",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Free Account",
  },
  {
    user: "Alice Johnson",
    email: "alice.johnson@example.com",
    phone: "+1122334455",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Paid Space Account",
  },

  {
    user: "Michael Taylor",
    email: "michael.taylor@example.com",
    phone: "+1555099887",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Paid Space Account",
  },
  {
    user: "Sophia Martinez",
    email: "sophia.martinez@example.com",
    phone: "+1333666555",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Free Account",
  },
  {
    user: "Michael Taeylor",
    email: "michael.taylor@example.com",
    phone: "+1555099887",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Paid Personal Account",
  },
  {
    user: "Sophia Marteinez",
    email: "sophia.martinez@example.com",
    phone: "+1333666555",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Paid Space Account",
  },
  {
    user: "John Doe",
    email: "john.doe@example.com",
    phone: "+1234567890",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Paid Space Account",
  },
  {
    user: "Jane Smith",
    email: "jane.smith@example.com",
    phone: "+1987654321",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Free Account",
  },
  {
    user: "Alice Johnson",
    email: "alice.johnson@example.com",
    phone: "+1122334455",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Paid Space Account",
  },
  {
    user: "Bob Brown",
    email: "bob.brown@example.com",
    phone: "+1443322110",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Free Account",
  },
  {
    user: "Emily Williams",
    email: "emily.williams@example.com",
    phone: "+1777000123",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Paid Personal Account",
  },
];
export const items2 = [
  {
    user: "John Doe",
    email: "john.doe@example.com",
    phone: "+1234567890",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Paid Space Account",
  },
  {
    user: "Jane Smith",
    email: "jane.smith@example.com",
    phone: "+1987654321",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Free Account",
  },
  {
    user: "Alice Johnson",
    email: "alice.johnson@example.com",
    phone: "+1122334455",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Paid Space Account",
  },
  {
    user: "Bob Brown",
    email: "bob.brown@example.com",
    phone: "+1443322110",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Free Account",
  },
  {
    user: "Emily Williams",
    email: "emily.williams@example.com",
    phone: "+1777000123",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Paid Personal Account",
  },

  {
    user: "Michael Taylor",
    email: "michael.taylor@example.com",
    phone: "+1555099887",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Paid Space Account",
  },
  {
    user: "Sophia Martinez",
    email: "sophia.martinez@example.com",
    phone: "+1333666555",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Free Account",
  },
  {
    user: "Michael Taeylor",
    email: "michael.taylor@example.com",
    phone: "+1555099887",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Paid Personal Account",
  },
  {
    user: "Sophia Marteinez",
    email: "sophia.martinez@example.com",
    phone: "+1333666555",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
    category: "Paid Space Account",
  },
];

export const items3 = [
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },
    spaceType: "community space",
    totalMember: "4.5k",
    admin: { name: "Sophia Marteinez", des: "@junior" },
    createdAt: "Mon, 13 Feb,2024",
    revenue: "333",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },
    spaceType: "community space",
    totalMember: "5.5k",
    admin: { name: "Michael Taeylor", des: "@junior" },
    createdAt: "Mon, 13 Feb,2024",
    revenue: "254",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },
    spaceType: "community space",
    totalMember: "7.5k",
    admin: { name: "Emily Williams", des: "@junior" },
    createdAt: "Mon, 13 Feb,2024",
    revenue: "234",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },
    spaceType: "community space",
    totalMember: "2.5k",
    admin: { name: "Bob Brown", des: "@junior" },
    createdAt: "Mon, 13 Feb,2024",
    revenue: "300",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },
    spaceType: "community space",
    totalMember: "1.5k",
    admin: { name: "John Doe", des: "@junior" },
    createdAt: "Mon, 13 Feb,2024",
    revenue: "100",
  },
];
export const items4 = [
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },
    createdAt: "Mon, 13 Feb,2024",
  },
];
export const items5 = [
  {
    user: "John Doe",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "Jane Smith",
    createdAt: "Mon, 13 Feb,2024",
  },

  {
    user: "Michael Taylor",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "Sophia Martinez",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "Michael Taylor",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "Sophia Martinez",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "Michael Taeylor",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "Sophia Marteinez",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "John Doe",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "Jane Smith",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "John Doe",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "Jane Smith",
    createdAt: "Mon, 13 Feb,2024",
  },
];
export const items6 = [
  {
    user: "John Doe",
    createdAt: "Mon, 13 Feb,2024",
    revenue: "333",
  },
  {
    user: "Jane Smith",
    createdAt: "Mon, 13 Feb,2024",
    revenue: "333",
  },

  {
    user: "Michael Taylor",
    createdAt: "Mon, 13 Feb,2024",
    revenue: "333",
  },
  {
    user: "Sophia Martinez",
    createdAt: "Mon, 13 Feb,2024",
    revenue: "333",
  },
  {
    user: "Michael Taylor",
    createdAt: "Mon, 13 Feb,2024",
    revenue: "333",
  },
  {
    user: "Sophia Martinez",
    createdAt: "Mon, 13 Feb,2024",
    revenue: "333",
  },
  {
    user: "Michael Taeylor",
    createdAt: "Mon, 13 Feb,2024",
    revenue: "333",
  },
  {
    user: "Sophia Marteinez",
    createdAt: "Mon, 13 Feb,2024",
    revenue: "333",
  },
  {
    user: "John Doe",
    createdAt: "Mon, 13 Feb,2024",
    revenue: "333",
  },
  {
    user: "Jane Smith",
    createdAt: "Mon, 13 Feb,2024",
    revenue: "333",
  },
];

export const items7 = [
  {
    user: "John Doe",
    email: "john.doe@example.com",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "Jane Smith",
    email: "jane.smith@example.com",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "Alice Johnson",
    email: "alice.johnson@example.com",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "Bob Brown",
    email: "bob.brown@example.com",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "Emily Williams",
    email: "emily.williams@example.com",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
  },

  {
    user: "Michael Taylor",
    email: "michael.taylor@example.com",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "Sophia Martinez",
    email: "michael.taylor@example.com",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "Michael Taeylor",
    email: "michael.taylor@example.com",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
  },
  {
    user: "Sophia Marteinez",
    email: "sophia.martinez@example.com",
    activity: "create account",
    createdAt: "Mon, 13 Feb,2024",
  },
];

export const items8 = [
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },

    createdAt: "Mon, 13 Feb,2024",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },

    createdAt: "Mon, 13 Feb,2024",
  },

  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },

    createdAt: "Mon, 13 Feb,2024",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },

    createdAt: "Mon, 13 Feb,2024",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },

    createdAt: "Mon, 13 Feb,2024",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },

    createdAt: "Mon, 13 Feb,2024",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum torem..." },

    createdAt: "Mon, 13 Feb,2024",
  },
];
export const items9 = [
  {
    space: { title: "Rukkor", des: "lorem ipsum porem....." },

    spaceDetail: "5.3k",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum porem....." },

    spaceDetail: "5.3k",
  },

  {
    space: { title: "Rukkor", des: "lorem ipsum porem....." },

    spaceDetail: "5.3k",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum porem....." },

    spaceDetail: "5.3k",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum porem....." },

    spaceDetail: "5.3k",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum porem....." },

    spaceDetail: "5.3k",
  },
  {
    space: { title: "Rukkor", des: "lorem ipsum porem....." },

    spaceDetail: "5.3k",
  },
];
export const userActivities = [
  {
    date: "Mon 4 Dec, 2023",
    login: "10:50 AM",
    logout: "02:50 PM",
    totalTime: "04:00 hrs",
    device: "Chrome - window",
  },
  {
    date: "Mon 4 Dec, 2023",
    login: "10:50 AM",
    logout: "02:50 PM",
    totalTime: "04:00 hrs",
    device: "Chrome - window",
  },
  {
    date: "Mon 4 Dec, 2023",
    login: "10:50 AM",
    logout: "02:50 PM",
    totalTime: "04:00 hrs",
    device: "Chrome - window",
  },
  {
    date: "Mon 4 Dec, 2023",
    login: "10:50 AM",
    logout: "02:50 PM",
    totalTime: "04:00 hrs",
    device: "Chrome - window",
  },
  {
    date: "Mon 4 Dec, 2023",
    login: "10:50 AM",
    logout: "02:50 PM",
    totalTime: "04:00 hrs",
    device: "Chrome - window",
  },
  {
    date: "Mon 4 Dec, 2023",
    login: "10:50 AM",
    logout: "02:50 PM",
    totalTime: "04:00 hrs",
    device: "Chrome - window",
  },
  {
    date: "Mon 4 Dec, 2023",
    login: "10:50 AM",
    logout: "02:50 PM",
    totalTime: "04:00 hrs",
    device: "Chrome - window",
  },
  {
    date: "Mon 4 Dec, 2023",
    login: "10:50 AM",
    logout: "02:50 PM",
    totalTime: "04:00 hrs",
    device: "Chrome - window",
  },
  {
    date: "Mon 4 Dec, 2023",
    login: "10:50 AM",
    logout: "02:50 PM",
    totalTime: "04:00 hrs",
    device: "Chrome - window",
  },
  {
    date: "Mon 4 Dec, 2023",
    login: "10:50 AM",
    logout: "02:50 PM",
    totalTime: "04:00 hrs",
    device: "Chrome - window",
  },
];
export const paiUserData = [
  {
    amount: "€4900",
    invoiceNumber: "INV-000070",
    createdAt: "Mon, 13 Feb,2024",
    status: "paid",
    action: "",
  },
  {
    amount: "€4900",
    invoiceNumber: "INV-000070",
    createdAt: "Mon, 13 Feb,2024",
    status: "paid",
    action: "",
  },
  {
    amount: "€4900",
    invoiceNumber: "INV-000070",
    createdAt: "Mon, 13 Feb,2024",
    status: "paid",
    action: "",
  },
  {
    amount: "€4900",
    invoiceNumber: "INV-000070",
    createdAt: "Mon, 13 Feb,2024",
    status: "paid",
    action: "",
  },
  {
    amount: "€4900",
    invoiceNumber: "INV-000070",
    createdAt: "Mon, 13 Feb,2024",
    status: "paid",
    action: "",
  },
  {
    amount: "€4900",
    invoiceNumber: "INV-000070",
    createdAt: "Mon, 13 Feb,2024",
    status: "paid",
    action: "",
  },
  {
    amount: "€4900",
    invoiceNumber: "INV-000070",
    createdAt: "Mon, 13 Feb,2024",
    status: "paid",
    action: "",
  },
  {
    amount: "€4900",
    invoiceNumber: "INV-000070",
    createdAt: "Mon, 13 Feb,2024",
    status: "paid",
    action: "",
  },
  {
    amount: "€4900",
    invoiceNumber: "INV-000070",
    createdAt: "Mon, 13 Feb,2024",
    status: "paid",
    action: "",
  },
  {
    amount: "€4900",
    invoiceNumber: "INV-000070",
    createdAt: "Mon, 13 Feb,2024",
    status: "paid",
    action: "",
  }, {
    amount: "€4900",
    invoiceNumber: "INV-000070",
    createdAt: "Mon, 13 Feb,2024",
    status: "paid",
    action: "",
  },
];

export const spaceActivity1 = [
 

  {
    user: "Michael Taylor",
    activity:"create a flow", createdAt: "Mon, 13 Feb,2024"
  },
  {
    user: "Sophia Martinez",
    activity:"create a flow", createdAt: "Mon, 13 Feb,2024"
  },
  {
    user: "Michael Taylor",
    activity:"create a flow", createdAt: "Mon, 13 Feb,2024"
  },
  {
    user: "Sophia Martinez",
    activity:"create a flow", createdAt: "Mon, 13 Feb,2024"
  },
  {
    user: "Michael Taeylor",
    activity:"create a flow", createdAt: "Mon, 13 Feb,2024"
  },
  {
    user: "Sophia Marteinez",
    activity:"create a flow", createdAt: "Mon, 13 Feb,2024"
  },
  {
    user: "John Doe",
    activity:"create a flow", createdAt: "Mon, 13 Feb,2024"
  },
  {
    user: "Jane Smith",
    activity:"create a flow", createdAt: "Mon, 13 Feb,2024"
  },
  {
    user: "John Doe",
   activity:"create a flow", createdAt: "Mon, 13 Feb,2024"
  },
  {
    user: "Jane Smith",
    activity:"create a flow", createdAt: "Mon, 13 Feb,2024"
  },
];