import React,{useState} from "react";
import { Card } from "@nextui-org/react";
import ImageConst from "./../../Constants/ImageConst";
import ColorConst from "./../../Constants/ColorConstant";
import FontConst, { fontConst } from "./../../Constants/FontConstant";
import { useAppSelector } from "./../../store/hooks";
import { selectedUser } from "./../../store/slices/adminSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import supabase from "./../../libs/SupabaseClient";
import AppConst from "./../../Constants/AppConstant";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
} from "@nextui-org/react";

import { Divider } from "@nextui-org/react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  User,
} from "@nextui-org/react";
import InputField from "./../common/InputField";
import ErrorMessage from "./../common/ErrorMessage";
import { toast } from "react-toastify";
import {
  containsEmojis,
  specialCharRegex,
} from "./../../utils/functions/commonFunctions";
const Header = (props: any) => {

  const schema = yup.object().shape({
    currentpwd: yup.string().required("current password is required."),
    newpwd: yup
      .string()
      .required("new password is required.")
      .min(8, "Must Contain 8 Characters.")
      .matches(/^\S+$/, " no white spaces allowed.")
      .test("no-emojis", "Emojis are not allowed in password", containsEmojis)
      // .max(20, "password exceeds the max. length of 20 characters.")
      .matches(specialCharRegex, "Must Contain  One Special Case Character.")
      .matches(/^(?=.*[a-z])/, " Must Contain One Lowercase Character.")
      .matches(/^(?=.*[A-Z])/, "Must Contain One Uppercase Character.")
      .matches(/^(?=.*[0-9])/, "Must Contain One Number Character."),

    confirmpwd: yup
      .string()
      .required("confirm password is required.")
      .test(
        "passwords-match",
        "Your passwords do not match.",
        function (value) {
          if (this.parent.newpwd === value) {
            return true;
          } else {
            return false;
          }
        }
      ),
  });
  const {
    register,
    handleSubmit,
    watch,
    setError,
    reset,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [isVisibleCurrentPwd, setIsVisibleCurrentPwd] = useState(false);
  const [isVisibleNewPwd, setIsVisibleNewPwd] = useState(false);
  const [isVisibleConfirmNewPwd, setIsVisibleConfirmNewPwd] = useState(false);

  // const [isVisible, setIsVisible] = useState(false);

  // const [open, setOpen] = useState(false);
  const value = watch();
  const userDetails: any = useAppSelector(selectedUser);

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      localStorage.removeItem("token");
      navigate(AppConst.logIn);
    } else {
      return;
    }
  };
  const handleSubmitForm: any = async (props: any) => {
    const {
      data: { user },
    } = await supabase.auth.signInWithPassword({
      email: userDetails[0]?.primary_email,
      password: value?.currentpwd,
    });
    if (user === null) {
      setError("currentpwd", {
        type: "manual",
        message: "Invalid current password",
      });
      return;
    } else {
      const { data, error } = await supabase.auth.updateUser({
        password: value?.confirmpwd,
      });
      if (error) {
        return toast?.error(`${error?.message}`);
      } else {
        if (data?.user !== null) {
         
          const { error } = await supabase.auth.signOut();
          if (!error) {
            toast?.success("Password Changed Successfully.Please try login again!");
            onOpenChange();
            localStorage.removeItem("token");
            navigate(AppConst.logIn);
          } else {
            return;
          }
        
        }
      }
    }
  };
  const toggleVisibilityforCurrentPwd: any = () => {
    setIsVisibleCurrentPwd(!isVisibleCurrentPwd);
  };
  const toggleVisibilityforNewPwd: any = () => {
    setIsVisibleNewPwd(!isVisibleNewPwd);
  };
  
  const toggleVisibilityforConfirmNewPwd: any = () => {
    setIsVisibleConfirmNewPwd(!isVisibleConfirmNewPwd);
  };

  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={3}
        sx={{
          height: "max-content",
          width: "auto",
          display: "flex",
          flexDirection: "row",
          // gap: "10%",
          position: "static",
          padding: "26px 48px",
        }}
      >
        <Box
          onClick={() => {
            props?.setOpen(false);
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              rowGap: "20px",
              fontFamily: "roboto-bold",
              color: ColorConst.fontBlack,
              fontWeight: FontConst.FONTWEIGHT_700,
              fontSize: FontConst.FONTSIZE_36,
              "@media only screen and (max-width: 1919px)": {
                fontWeight: FontConst.FONTWEIGHT_600,
                fontSize: FontConst.FONTSIZE_36,
              },
              /* Large Screens */
              "@media only screen and (max-width: 1439px)": {
                fontWeight: FontConst.FONTWEIGHT_600,
                fontSize: FontConst.FONTSIZE_32,
              },
              /* Medium Screens */
              "@media only screen and (max-width: 1280px)": {
                fontWeight: FontConst.FONTWEIGHT_500,
                fontSize: "28px",
              },
              /* Small Screens */
              "@media only screen and (max-width: 1024px)": {
                fontWeight: FontConst.FONTWEIGHT_500,
                fontSize: "28px",
              },
            }}
          >
            {`Hello, ${
              userDetails?.[0]?.username ? userDetails?.[0]?.username : "User"
            }`}
            <div>
              <img
                src={ImageConst?.emoji}
                alt="emoji"
                style={{ width: "32px", height: "32px" }}
              />
            </div>
          </Box>
          <Box
            mt={1.5}
            sx={{
              color: ColorConst.fontBlackMini,
              fontWeight: FontConst.FONTWEIGHT_400,
              fontSize: FontConst.FONTSIZE_16,
              "@media only screen and (max-width: 1919px)": {
                fontWeight: FontConst.FONTWEIGHT_400,
                fontSize: FontConst.FONTSIZE_16,
              },
              /* Large Screens */
              "@media only screen and (max-width: 1439px)": {
                fontWeight: FontConst.FONTWEIGHT_400,
                fontSize: FontConst.FONTSIZE_16,
              },
              /* Medium Screens */
              "@media only screen and (max-width: 1280px)": {
                fontWeight: FontConst.FONTWEIGHT_400,
                fontSize: FontConst.FONTSIZE_14,
              },
              /* Small Screens */
              "@media only screen and (max-width: 1023px)": {
                fontWeight: FontConst.FONTWEIGHT_400,
                fontSize: FontConst.FONTSIZE_14,
                display: "none",
              },
            }}
          >
            Here some reports on your dashboard
          </Box>
        </Box>
        <Box
          onClick={() => {
            props?.setOpen(false);
          }}
          sx={{
            display: "flex",
            flexDirection: "row",
            margin: "0px 0px 0px auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "480px",
              margin: "0px 20px 0px 0px",
              "@media only screen and (max-width: 1919px)": {
                width: "380px",
              },
              /* Large Screens */
              "@media only screen and (max-width: 1439px)": {
                width: "320px",
              },
              /* Medium Screens */
              "@media only screen and (max-width: 1280px)": {
                width: "280px",
              },
              /* Small Screens */
              "@media only screen and (max-width: 1024px)": {
                width: "250px",
              },
              "@media only screen and (max-width: 850px)": {
                width: "250px",
              },
              "@media only screen and (max-width: 768px)": {
                width: "250px",
              },
            }}
          >
            <input
              // variant="flat"
              placeholder=" Type to Search"
              style={{
                color: ColorConst?.grayPlaceholder,
                fontSize: fontConst?.FONTSIZE_14,
                fontWeight: 400,
                width: "88%",
                height: "42px",
                borderRadius: "14px",
                paddingLeft: "44px",
                border: `1px solid transparent`,
                background: ColorConst.primaryGray,
              }}
            />
            <div style={{ position: "absolute", top: 14, left: 20 }}>
              <img src={ImageConst.headerSearch} alt="icon" />
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                // height: "30px",
                background: ColorConst.primaryGray,
                border: "1px solid transparent",
                borderRadius: "8px",
                padding: "8px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={ImageConst?.bell}
                alt="emoji"
                style={{ width: "24px", height: "24px" }}
              />
            </div>
          </Box>
        </Box>
        <Box
          onClick={() => {
            props?.setOpen(!props?.open);
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Divider
            orientation="vertical"
            style={{
              background: ColorConst.primaryGray,
              width: "2px",
              height: "60%",
            }}
          />
        </Box>

        <Box>
          <Popover
          isKeyboardDismissDisabled={true}
            radius="sm"
            placement="bottom-end"
            isOpen={props?.open}
            onOpenChange={() => {
              props?.setOpen(!props?.open);
            }}
          >
            <PopoverTrigger>
              <Card
                style={{
                  borderRadius: "14px",
                  background: "transparent",
                  border: `1px solid ${ColorConst.primaryGray}`,
                  padding: "12px",
                  display: "flex",
                  flexDirection: "row",

                  gap: "10px",
                  cursor: "pointer",
                }}
                shadow="none"
              >
                <img
                  src={ImageConst?.person}
                  alt="emoji"
                  style={{ width: "48px", height: "48px" }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    "@media only screen and (max-width: 1024px)": {
                      display: "none",
                    },
                  }}
                >
                  <div
                    style={{
                      color: ColorConst.fontBlack,
                      fontWeight: FontConst.FONTWEIGHT_400,
                      fontSize: FontConst.FONTSIZE_16,
                    }}
                  >
                    {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                    {`${
                      userDetails?.[0]?.username
                        ? userDetails?.[0]?.username
                        : "User"
                    }`}
                  </div>
                  <div
                    style={{
                      color: ColorConst.fontGray,
                      fontWeight: FontConst.FONTWEIGHT_400,
                      fontSize: FontConst.FONTSIZE_12,
                    }}
                  >
                    Admin
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // background: ColorConst.primaryGray,
                    // border: "1px solid transparent",
                    // borderRadius: "8px",
                    // padding: "10px",
                    "@media only screen and (max-width: 1024px)": {
                      display: "none",
                    },
                  }}
                >
                  <img
                    src={ImageConst?.right_arrow}
                    alt="emoji"
                    style={{
                      width: "100%",
                      // height: "100%",
                    }}
                  />
                </Box>
              </Card>
            </PopoverTrigger>
            <PopoverContent>
              {(titleProps) => (
                <Box
                  py={2}
                  px={1}
                  width={"250px"}
                  sx={{
                    color: ColorConst.fontBlack,
                    fontSize: FontConst.FONTSIZE_16,
                    fontWeight: FontConst.FONTWEIGHT_400,
                  }}
                >
                  <User
                    avatarProps={{ radius: "lg", src: ImageConst?.person }}
                    description={userDetails?.[0]?.primary_email}
                    name={
                      userDetails?.[0]?.username
                        ? userDetails?.[0]?.username
                        : "User"
                    }
                  />

                  <Divider />
                  <Box display={"flex"} flexDirection={"column"} my={2} gap={2}>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      gap={2}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        props?.setOpen(false);
                        onOpen();
                      }}
                    >
                      <Box mt={0.2} sx={{ cursor: "pointer" }}>
                        <img src={ImageConst.resetPassword} alt="icon" />
                      </Box>
                      <Box
                        sx={{
                          fontSize: FontConst.FONTSIZE_14,
                          fontWeight: FontConst.FONTWEIGHT_400,
                          color: ColorConst.fontBlack,
                        }}
                      >
                        <button
                          // disableRipple
                          // disableAnimation

                          style={{
                            fontSize: FontConst.FONTSIZE_14,
                            fontWeight: FontConst.FONTWEIGHT_400,
                            color: ColorConst.fontBlack,
                            padding: "0px !important",
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "start",
                            textDecoration: "none",
                            border: "0px solid transparent",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                        >
                          Reset Password
                        </button>
                      </Box>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      gap={3}
                      mx={0.7}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        handleSignOut();
                      }}
                    >
                      <Box>
                        <img src={ImageConst.logout} alt="icon" />
                      </Box>
                      <Box
                        sx={{
                          fontSize: FontConst.FONTSIZE_14,
                          fontWeight: FontConst.FONTWEIGHT_400,
                          color: ColorConst.fontBlack,
                        }}
                      >
                        Sign Out
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </PopoverContent>
          </Popover>
        </Box>
        <Modal
          size="lg"
          closeButton={
            <Box mt={4} mx={3} sx={{ cursor: "pointer" }}>
              <img
                src={ImageConst?.close}
                alt="close"
                onClick={() => {
                  reset();
                }}
              />
            </Box>
          }
          backdrop="opaque"
          isOpen={isOpen}
          onOpenChange={() => {
            onOpenChange();
            reset();
          }}
          motionProps={{
            variants: {
              enter: {
                y: 0,
                opacity: 1,
                transition: {
                  duration: 0.3,
                  ease: "easeOut",
                },
              },
              exit: {
                y: -20,
                opacity: 0,
                transition: {
                  duration: 0.2,
                  ease: "easeIn",
                },
              },
            },
          }}
        >
          <ModalContent>
            {(onClose) => (
              <Box>
                <form onSubmit={handleSubmit((data) => handleSubmitForm(data))}>
                  <ModalHeader className="flex flex-col gap-1">
                    <Box
                      mt={4}
                      sx={{
                        fontSize: FontConst.FONTSIZE_24,
                        fontWeight: FontConst.FONTWEIGHT_500,
                      }}
                    >
                      Reset Password
                    </Box>
                  </ModalHeader>
                  <Box mx={3} my={1}>
                    <Divider />
                  </Box>{" "}
                  <ModalBody>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={3}
                      mt={1}
                    >
                      <Box display={"flex"} flexDirection={"column"} gap={1}>
                        <Box>Current Password</Box>
                        <Box>
                          <InputField
                        isVisible={isVisibleCurrentPwd}
                        setIsVisible={setIsVisibleCurrentPwd}
                        toggleVisibility={toggleVisibilityforCurrentPwd}
                            type={{
                              ...register("currentpwd", { required: true }),
                            }}
                            placeholder={"Enter your current password"}
                            st={{ width: "100%" }}
                          />
                        </Box>
                        {errors?.currentpwd && (
                          <ErrorMessage
                            message={
                              errors.currentpwd && errors.currentpwd.message
                            }
                          />
                        )}
                      </Box>
                      <Box display={"flex"} flexDirection={"column"} gap={1}>
                        <Box>New Password</Box>
                        <Box>
                          <InputField
                            isVisible={isVisibleNewPwd}
                            setIsVisible={setIsVisibleNewPwd}
                            toggleVisibility={toggleVisibilityforNewPwd}
                            type={{
                              ...register("newpwd", { required: true }),
                            }}
                            placeholder={"Enter your new password"}
                            st={{ width: "100%" }}
                          />
                        </Box>
                        {errors?.newpwd && (
                          <ErrorMessage
                            message={errors.newpwd && errors.newpwd.message}
                          />
                        )}
                      </Box>
                      <Box display={"flex"} flexDirection={"column"} gap={1}>
                        <Box>Confirm New Password</Box>
                        <Box>
                          <InputField
                             isVisible={isVisibleConfirmNewPwd}
                             setIsVisible={setIsVisibleConfirmNewPwd}
                             toggleVisibility={toggleVisibilityforConfirmNewPwd}
                            type={{
                              ...register("confirmpwd", { required: true }),
                            }}
                            placeholder={"Enter your confirm new password"}
                            st={{ width: "100%" }}
                          />
                        </Box>
                        {errors?.confirmpwd && (
                          <ErrorMessage
                            message={
                              value?.newpwd?.length > 0 &&
                              value?.confirmpwd?.length > 0 &&
                              value?.newpwd === value?.confirmpwd
                                ? " "
                                : errors.confirmpwd && errors.confirmpwd.message
                            }
                          />
                        )}
                      </Box>
                    </Box>
                  </ModalBody>
                  <Box mx={3} mt={3.5} mb={1}>
                    <Divider />
                  </Box>
                  <Box mx={3} my={2} mb={4}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3, xl: 4 }}
                      columns={12}
                    >
                      <Grid item xs={6}>
                        <Box
                          onClick={() => {
                            reset();
                            onClose();
                          }}
                        >
                          <button
                            type="reset"
                            style={{
                              fontSize: FontConst.FONTSIZE_16,
                              fontWeight: FontConst.FONTWEIGHT_500,
                              width: "100%",
                              height: "54px",
                              color: ColorConst.white,
                              background: ColorConst.darkGray,
                              border: "1px solid transparent",
                              borderRadius: "12px",
                              cursor: "pointer",
                            }}
                          >
                            Cancel
                          </button>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box>
                          <button
                            type="submit"
                            style={{
                              fontSize: FontConst.FONTSIZE_16,
                              fontWeight: FontConst.FONTWEIGHT_500,

                              width: "100%",
                              height: "54px",
                              color: ColorConst.white,
                              background: ColorConst.Orange,
                              border: "1px solid transparent",
                              borderRadius: "12px",
                              cursor: "pointer",
                            }}
                          >
                            Update Password{" "}
                          </button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              </Box>
            )}
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};

export default Header;
