import React from "react";
import { Box, Grid } from "@mui/material";

import { Card, CardHeader, CardBody, Avatar, Button } from "@nextui-org/react";
import colorConst from "../../../Constants/ColorConstant";
import fontConst from "../../../Constants/FontConstant";
import ImageConst from "../../../Constants/ImageConst";

const SpaceDetailComponent = (props: any) => {
  return (
    <Box>
      {" "}
      <Card shadow="none" radius="sm" style={{ padding: "23px " }}>
        <CardHeader>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Box
              sx={{
                color: colorConst.fontBlack,
                fontWeight: fontConst.FONTWEIGHT_700,
                fontSize: fontConst.FONTSIZE_20,
              }}
            >
              Spaces
            </Box>
            <Box
              sx={{
                color: colorConst.fontGray,
                fontWeight: fontConst.FONTWEIGHT_400,
                fontSize: fontConst.FONTSIZE_14,
              }}
            >
              All space to the user
            </Box>
          </Box>
        </CardHeader>
        <CardBody>
          <Box mr={2} ml={-1}>
            <Grid container columns={24} columnSpacing={2} rowSpacing={2}>
              {[1, 2, 3, 4]?.map((i: any) => {
                return (
                  <Grid item xs={6} key={i}>
                    <Card
                      radius="sm"
                      style={{
                        borderRadius: "14px",
                        background: "transparent",
                        border: `3px solid ${colorConst.primaryGray}`,
                        paddingBottom: "20px",

                        gap: "10px",
                      }}
                      shadow="none"
                    >
                      <Box sx={{ position: "relative" }}>
                        <img
                          src={ImageConst.spaceBG}
                          alt="icon"
                          style={{ width: "100%", height: "158px" }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          mt={-6}
                        >
                          <Avatar
                            src={ImageConst?.Rukkor_logo}
                            style={{ width: "80px", height: "auto" }}
                          />
                        </Box>
                      </Box>
                      <Box px={2}>
                        <Box
                          sx={{
                            fontSize: fontConst.FONTSIZE_24,
                            fontWeight: fontConst.FONTWEIGHT_400,
                          }}
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          Rukkor (Community)
                        </Box>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: fontConst.FONTSIZE_14,
                            fontWeight: fontConst.FONTWEIGHT_400,
                            color: colorConst.fontBlackMini,
                            textAlign: "center",
                          }}
                        >
                          The official community Space for Rukkor and its
                          products and services.
                        </p>

                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Grid
                            container
                            columnSpacing={2}
                            rowSpacing={2}
                            columns={24}
                          >
                            <Grid item xs={8} key={i}>
                              <Card
                                radius="sm"
                                style={{
                                  borderRadius: "14px",
                                  background: "transparent",
                                  border: `1px dashed ${colorConst.dashedBorder}`,
                                  padding: "12px",
                                  display: "flex",
                                  flexDirection: "row",

                                  gap: "10px",
                                }}
                                shadow="none"
                              >
                                <Box
                                  gap={1}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    width: "max-content",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: colorConst.fontBlack,
                                      fontWeight: fontConst.FONTWEIGHT_400,
                                      fontSize: fontConst.FONTSIZE_14,
                                    }}
                                  >
                                    {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                                    Community
                                  </div>
                                  <div
                                    style={{
                                      color: colorConst.fontGray,
                                      fontWeight: fontConst.FONTWEIGHT_400,
                                      fontSize: fontConst.FONTSIZE_14,
                                    }}
                                  >
                                    Space
                                  </div>
                                </Box>
                              </Card>
                            </Grid>
                            <Grid item xs={8} key={i}>
                              <Card
                                radius="sm"
                                style={{
                                  borderRadius: "14px",
                                  background: "transparent",
                                  border: `1px dashed ${colorConst.dashedBorder}`,
                                  padding: "12px",
                                  display: "flex",
                                  flexDirection: "row",

                                  gap: "10px",
                                }}
                                shadow="none"
                              >
                                <Box
                                  gap={1}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    width: "max-content",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: colorConst.fontBlack,
                                      fontWeight: fontConst.FONTWEIGHT_400,
                                      fontSize: fontConst.FONTSIZE_14,
                                    }}
                                  >
                                    {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                                    Admin
                                  </div>
                                  <div
                                    style={{
                                      color: colorConst.fontGray,
                                      fontWeight: fontConst.FONTWEIGHT_400,
                                      fontSize: fontConst.FONTSIZE_14,
                                    }}
                                  >
                                    Role
                                  </div>
                                </Box>
                              </Card>
                            </Grid>{" "}
                            <Grid item xs={8} key={i}>
                              <Card
                                radius="sm"
                                style={{
                                  borderRadius: "14px",
                                  background: "transparent",
                                  border: `1px dashed ${colorConst.dashedBorder}`,
                                  padding: "12px",
                                  display: "flex",
                                  flexDirection: "row",

                                  gap: "10px",
                                }}
                                shadow="none"
                              >
                                <Box
                                  gap={1}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    width: "max-content",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: colorConst.fontBlack,
                                      fontWeight: fontConst.FONTWEIGHT_400,
                                      fontSize: fontConst.FONTSIZE_14,
                                    }}
                                  >
                                    {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                                    English
                                  </div>
                                  <div
                                    style={{
                                      color: colorConst.fontGray,
                                      fontWeight: fontConst.FONTWEIGHT_400,
                                      fontSize: fontConst.FONTSIZE_14,
                                    }}
                                  >
                                    Language
                                  </div>
                                </Box>
                              </Card>
                            </Grid>{" "}
                            <Grid item xs={24} key={i}>
                              <Card
                                radius="sm"
                                style={{
                                  borderRadius: "14px",
                                  background: "transparent",
                                  border: `1px dashed ${colorConst.dashedBorder}`,
                                  padding: "12px",
                                  display: "flex",
                                  flexDirection: "row",

                                  gap: "10px",
                                }}
                                shadow="none"
                              >
                                <Box
                                  gap={1}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    width: "max-content",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: colorConst.fontBlack,
                                      fontWeight: fontConst.FONTWEIGHT_400,
                                      fontSize: fontConst.FONTSIZE_14,
                                    }}
                                  >
                                    {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                                    Tue 19 October, 2023
                                  </div>
                                  <div
                                    style={{
                                      color: colorConst.fontGray,
                                      fontWeight: fontConst.FONTWEIGHT_400,
                                      fontSize: fontConst.FONTSIZE_14,
                                    }}
                                  >
                                    Space Created Date
                                  </div>
                                </Box>
                              </Card>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mt={4}>
                          <Button
                            disableAnimation
                            disableRipple
                            // variant="flat"
                            style={{
                              color: colorConst?.white,
                              fontSize: fontConst?.FONTSIZE_14,
                              fontWeight: 400,
                              width: "100%",
                              height: "35px",
                              borderRadius: "12px",
                              border: `1px solid transparent`,
                              background: colorConst.Orange,
                            }}
                          >
                            View Space
                          </Button>
                        </Box>
                        <Box mt={2}>
                          <Button
                            onClick={() => {
                              props?.onOpen();
                            }}
                            disableAnimation
                            disableRipple
                            // variant="flat"
                            style={{
                              cursor: "pointer",
                              color: colorConst?.Orange,
                              fontSize: fontConst?.FONTSIZE_14,
                              fontWeight: 400,
                              width: "100%",
                              height: "35px",
                              borderRadius: "12px",
                              border: `1px dashed ${colorConst.Orange}`,
                              background: colorConst.white,
                            }}
                          >
                            View Terms and Rules
                          </Button>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </CardBody>
      </Card>
    </Box>
  );
};

export default SpaceDetailComponent;
