import React, { useState } from "react";
import { Card, CardHeader, CardBody } from "@nextui-org/react";
import fontConst from "./../../Constants/FontConstant";

import { Box, Typography } from "@mui/material";
import { AreaChat } from "./AreaChart";
const SpaceChart = (props: any) => {
  
  const [selected] = useState<any>("Annually");
  const Annually = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUNE",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const Weekly = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  return (
    <Box>
      <Card shadow="none" style={{ borderRadius: "6px", padding: "16px" }}>
        <CardHeader className="flex justify-between box-border gap-3">
          <Box>
            <Typography
              sx={{ fontSize: fontConst.FONTSIZE_20, fontWeight: "700" }}
            >
              {props?.title}
            </Typography>
            <Box
              mt={1}
              style={{ color: "#999999", fontSize: "14px", fontWeight: 400 }}
            >
              {props?.description}
            </Box>{" "}
          </Box>
        </CardHeader>
        <CardBody className={"box-border"}>
          <AreaChat
          YAxis={props?.YAxis}
            labels={ props?.label? props?.label : selected === "Annually" ? Annually : Weekly}
            isDisplayRightSide={props?.isDisplayRightSide}
          />
        </CardBody>
      </Card>
    </Box>
  );
};

export default SpaceChart;
