import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { fakerDE as faker } from "@faker-js/faker";
import "./bar-chart.css";
import { Box } from "@mui/material";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// const backgroundBar = {
//   id: "backgroundBar",
//   beforeDatasetDraw(chart: any, args: any, pluginOptions: any) {
//     const {
//       data,
//       ctx,
//       chartArea: { top, bottom, left, right, width, height },
//       scales: { x, y },
//     } = chart;
//     ctx.save();
//     const segment = width / data.labels.width;
//     console.log(segment);
//     ctx.fillStyle = "rgba(31, 13, 4, 1)";
//     ctx.fillRect(x.getPixelForValue(0), top, segment, height);
//   },
// };
export function BarChart(props: any) {

  const options: any = {
    
    indexAxis: "x",
    responsive: true,
    plugins: {
      legend: false,
      colors: {
        forceOverride: true,
      },
      title: {
        display: false, // title hidden on false
        text: "Bar Chart for Category Distribution",
      },
      remainingBarsColor: {
        aboveBarColor: "rgba(31, 13, 4, 1)", // Color for remaining empty bars above filled bars
      },
      tooltip: {
        mode: "index",
        position: "average",
        backgroundColor: "rgba(31, 13, 4, 1)",
        displayColors: false,
        yAlign: "bottom",
        xAlign: "center",

        yPadding: 15,
        xPadding: 45,
        titleAlign: "center",
        footerAlign: "center",
        bodyAlign: "center",
        callbacks: {
          title: (ctx: any) => {
            return props?.isLogin ? null : "Space Created";
          },
        },
      },
    },
    
   layout:{
   padding:{
  
   },
 
   },
    scales: {
      x: {
        grid: {
          display: false, // To remove x-axis gridlines
        },
      },
      y: {
        grid: {
          color: "#d3d3d3",
        },
        border: {
          dash: [5, 5],
        },
    
        beginAtZero: true,
        grace: 3,
        min: 0,
        // max: 100,
        ticks: {
          callback: function (value: any, index: any, values: any) {
            return value / 1000 + "k";
          },
        },
      },
    },
    

    barPercentage: 0.8, // 80% of the available width for each bar
    categoryPercentage: 0.8 ,
    tooltips: {
      enabled: true,
      position: "nearest",
    },
  };
  const dataFun = {
    labels: props?.labels,
    datasets: [
      {
        // label: "Space Created ",

        maxBarThickness: props?.isLogin ? 30 : 15,
        barStyle: "round",
        data: props?.labels?.map(() => faker.datatype.number({ min: 0, max: 7000 })),
        backgroundColor: props?.isLogin ? "#FDF0E8" : "#E7651C",
        hoverBackgroundColor: props?.isLogin ? "#E7651C" : "#E7651C",
        borderRadius: 5, // Set the border radius for bars
        // categoryPercentage: 0.5, // Adjust the space between the bars
      },
    ],
  };

  return (
    <Box>
      <Bar
        className="bar-chart"
        options={options}
        data={dataFun}
        style={{
          maxHeight: props?.isLogin ? "auto" : "400px",
          paddingRight: props?.isLogin ? "32px" : "32px",
        }}
      />
    </Box>
  );
}
