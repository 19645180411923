import React from "react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import ImageConst from "./../../Constants/ImageConst";
import { SortingOptions } from "./../../utils/functions/commonFunctions";
const SortButton = (props: any) => {
  const dropDownOptionsForSorting: any = SortingOptions?.map((item: any) => ({
    ...item,
    label: item.label,
    value: item.value,
  }));
  return (
    <div>
      <Dropdown placement="top-end" shouldBlockScroll={true}>
        <DropdownTrigger>
          <img src={ImageConst?.filter} alt="filter" style={{cursor:"pointer"}} />
        </DropdownTrigger>
        <DropdownMenu
          aria-label="Single selection example"
          className="flex items-center"
          disallowEmptySelection
          selectionMode="single"
          selectedKeys={props?.selectedKeys}
        >
          {dropDownOptionsForSorting?.map((i: any, index: any) => {
            return (
              <DropdownItem
                onClick={() => {
                  props?.setSelectedKeys(i?.label);
                }}
                // onClick={() => {
                // }}
                key={index}
                className="flex items-left"
                style={{
                  width: "fit-content",
                  border: "1px solid transparent",
                  background: "transparent",
                }}
                textValue={i?.label}
                // endContent={
                //   i?.label == selectedKeys && (
                //     <img src={ImageConst?.right_arrow} alt="icon" />
                //   )
                // }
              >
                {i?.label}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default SortButton;
