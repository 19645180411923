import React, { useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  User,
  Chip,
  Pagination,
  Button,
} from "@nextui-org/react";

import { Box, Grid } from "@mui/material";
import ImageConst from "./../../Constants/ImageConst";
import colorConst from "./../../Constants/ColorConstant";
import fontConst from "./../../Constants/FontConstant";
import SortButton from "./SortButton";

const CustomTable = (props: any) => {
  // const [filterValue, setFilterValue] = React.useState("");
  // const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
  // const [statusFilter, setStatusFilter] = React.useState("all");
  const [rowsPerPage] = React.useState(4);
  const [selectedKeys, setSelectedKeys] = useState<any>("Default (No Sorting)");

  // const [sortDescriptor, setSortDescriptor] = React.useState({
  //   column: "age",
  //   direction: "ascending",
  // });
  const keys = props?.userTableData?.reduce((acc: any, obj: any) => {
    return acc.concat(Object.keys(obj));
  }, []);
  const uniqueKeys = Array.from(new Set(keys));
  const getColor = (prop: any) => {
    switch (prop) {
      case "Free Account":
        return "#F8285A";
      case "Paid Space Account":
        return "#00A58D";
      case "Paid Personal Account":
        return "#C69B04";
      default:
        return "gray";
    }
  };
  const getBackgroundColor = (prop: any) => {
    switch (prop) {
      case "Free Account":
        return "#F8285A26";
      case "Paid Space Account":
        return "#00A58D26";
      case "Paid Personal Account":
        return "#C69B0426";
      default:
        return "#f4f4f4";
    }
  };
  const renderCell = React.useCallback((user: any, columnKey: any) => {
    switch (columnKey) {
      case "user":
        return (
          <User
            avatarProps={{ radius: "lg", src: ImageConst?.peer }}
            description={user}
            name={user}
          >
            {user}
          </User>
        );

      case "email":
        return (
          <Box
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            gap={1}
          >
            <img src={ImageConst?.email} alt="email" />
            {user}
          </Box>
        );
      case "phone":
        return (
          <Box
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            gap={1}
          >
            <img src={ImageConst?.phone} alt="phone" />
            {user}
          </Box>
        );
      case "admin":
        return (
          <User
            avatarProps={{ radius: "lg", src: ImageConst?.person }}
            description={user["des"]}
            name={user["name"]}
          >
            {user["name"]}
          </User>
        );
      case "space":
        return (
          <User
            avatarProps={{ radius: "lg", src: ImageConst?.Rukkor_logo }}
            description={user["des"]}
            name={user["title"]}
          >
            {user["title"]}
          </User>
        );
      case "spaceD":
        return (
          <User
            avatarProps={{ radius: "lg", src: ImageConst?.Rukkor_logo }}
            description={user["des"]}
            name={user["title"]}
          >
            {user["title"]}
          </User>
        );
      case "createdAt":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              justifyContent: "left",
            }}
          >
            {user}
            <Box>{"10:34pm"}</Box>
          </Box>
        );
      case "spaceDetail":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "right",
              justifyContent: "right",
            }}
          >
            <Box sx={{ float: "right" }}>
              {user}
              <Box
                sx={{
                  color: colorConst?.fontGray,
                }}
              >
                Total Member
              </Box>
            </Box>
          </Box>
        );
      case "category":
        return (
          <Box>
            <Chip
              radius="sm"
              style={{
                color: getColor(user),
                background: getBackgroundColor(user),
              }}
            >
              {user}
            </Chip>
          </Box>
        );

      case "action":
        return (
          <Box>
            <Button
              disableAnimation
              disableRipple
              style={{
                // padding:"10px2px",
                border: `1px solid ${colorConst.Orange}`,
                background: "#fff",
                color: colorConst.Orange,
                borderRadius: "12px",
              }}
            >
              Send Invoice
            </Button>
          </Box>
        );
      default:
        return user;
    }
  }, []);
  const [page, setPage] = React.useState(1);
  const pages = Math.ceil(props?.userTableData?.length / rowsPerPage);
  // const items = React.useMemo(() => {
  //   const start = (page - 1) * rowsPerPage;
  //   const end = start + rowsPerPage;

  //   return filteredItems.slice(start, end);
  // }, [page, filteredItems, rowsPerPage]);
  const onNextPage = React.useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  }, [page, pages]);

  const onPreviousPage = React.useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  // const onRowsPerPageChange = React.useCallback((e) => {
  //   setRowsPerPage(Number(e.target.value));
  //   setPage(1);
  // }, []);

  const bottomContent = React.useMemo(() => {
    return (
      <div>
        {props?.userTableData?.length > 0 ? (
          <div className=" py-2 px-2 flex justify-between items-center">
            {!props?.isType && (
              <span className="px-3 text-small text-default-400">
                All items displayed
              </span>
            )}
            <Box mr={-4}>
              <Button
                disableAnimation
                style={{
                  background: "transparent",
                  // props?.isType === "3"
                  //   ? colorConst.lightGray
                  //   : colorConst?.Orange,
                  color: colorConst.fontBlackMini,
                  fontSize: fontConst.FONTSIZE_14,
                  fontWeight: fontConst.FONTWEIGHT_400,
                  border: `1px solid ${colorConst.borderGray}`,
                }}
                radius="sm"
                isDisabled={pages === 1}
                size="sm"
                // variant="flat"
                onPress={onPreviousPage}
              >
                Previous
              </Button>
            </Box>

            <Pagination
              radius="sm"
              variant="light"
              isCompact
              // showControls
              // showShadow
              page={page}
              total={pages}
              // color="secondary"
              // color="default"
              // page={page}
              // total={pages}
              // color="customGreen" // Use the custom color scheme
              color="primary"
              onChange={setPage}
              classNames={{
                wrapper: "gap-0 ",
                item: " cursor-pointer  mx-1 bg-success",
                // cursor:
                //   `bg-gradient-to-b shadow-lg from-${theme.colors.customGreen.DEFAULT}
                //    to-${theme.colors.customGreen.DEFAULT}
                //    text-white font-bold`,
              }}
            />
            <Box ml={1}>
              <Button
                disableAnimation
                style={{
                  background: "transparent",
                  // background:
                  //   props?.isType === "3"
                  //     ? colorConst.lightGray
                  //     : colorConst?.Orange,
                  color: colorConst.fontBlackMini,
                  fontSize: fontConst.FONTSIZE_14,
                  fontWeight: fontConst.FONTWEIGHT_400,
                  border: `1px solid ${colorConst.borderGray}`,
                }}
                radius="sm"
                isDisabled={pages === 1}
                size="sm"
                onPress={onNextPage}
              >
                Next
              </Button>
            </Box>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }, [
    page,
    pages,
    onNextPage,
    onPreviousPage,
    props?.isType,
    props?.userTableData?.length,
  ]);
  return (
    <Box>
      <Table
        shadow="none"
        radius="sm"
        topContent={
          props?.isType === "2" || props?.isType === "3" ? (
            <Grid
              container
              // columnSpacing={props?.hasSearch ? 50 : 12}
              columns={12}
            >
              <Grid item xs={10}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    my: "10px",
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "20px",
                      fontWeight: 700,
                      fontFamily: "roboto",
                    }}
                  >
                    {props?.title}
                  </Box>
                  <Box
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      fontFamily: "roboto",
                      color: colorConst.fontGray,
                    }}
                  >
                    {props?.description}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  mt={2}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"end"}
                >
                  {props?.isType === "3" && props?.hasSearch === true && (
                    <Box display={"flex"} flexDirection={"row"} gap={2}>
                      <Box>
                        {" "}
                        <img src={ImageConst?.search} alt="icon" />
                      </Box>
                      <Box>
                        <SortButton
                          selectedKeys={selectedKeys}
                          setSelectedKeys={setSelectedKeys}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box></Box>
              </Grid>
            </Grid>
          ) : (
            ""
          )
        }
        hideHeader={props?.isType === "2" ? true : false}
        aria-label="UserData table"
        bottomContent={
          props?.isType === "2" ? (
            <></>
          ) : (
            <div className="flex w-full justify-end">{bottomContent}</div>
          )
        }
        classNames={{
          base: "max-h-[520px] overflow-scroll",
          table: "min-h-[420px]",
        }}
      >
        <TableHeader>
          {props?.Users?.map((i: any) => {
            return (
              <TableColumn
                style={{
                  textTransform: "uppercase",
                  fontSize: fontConst.FONTSIZE_12,
                  fontWeight: 600,
                }}
                key={i}
              >
                {i}
              </TableColumn>
            );
          })}
        </TableHeader>
        <TableBody items={props?.userTableData} emptyContent={"No data found"}>
          {props?.userTableData?.map((rowData: any, rowIndex: any) => (
            <TableRow key={rowIndex}>
              {uniqueKeys?.map((columnKey: any, columnIndex: any) => (
                <TableCell
                  onClick={() => {
                    props?.onClick && props?.onClick(rowData);
                  }}
                  key={columnIndex}
                  style={{
                    borderBottom:
                      props?.isType === "2" ? "none" : "1px solid #f4f4f4",
                    height: "56px", //35px
                    fontSize: "14px",
                    fontWeight: 400,
                    cursor: props?.onClick ? "pointer" : "default",
                    fontFamily: "roboto",
                  }}
                >
                  {renderCell(rowData[columnKey], columnKey)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default CustomTable;
