import React from "react";
import { Breadcrumbs, Box, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import colorConst from "./../../Constants/ColorConstant";
import fontConst from "./../../Constants/FontConstant";
import { Card, CardBody } from "@nextui-org/react";
import RevenueChart from "../charts/RevenueChart";
import CustomTable from "../common/CustomTable";
import { paiUserData, items7, spaceActivity1 } from "./../../static/tableData";
import {
  paidUser,
  userActivityheader,
  spaceActivity,
} from "./tables/TableHeader";
import { useAppDispatch } from "./../../store/hooks";
import {
  NavigatedFromSpace,
  isSpaceState,
} from "./../../store/slices/adminSlice";
import { useDisclosure } from "@nextui-org/react";
import TnCModal from "./TnCModal/TnCModal";
import SpaceDetailsComponent from "./SpaceDetails/SpaceDetailsComponent";
import SpaceProductsComponent from "./SpaceDetails/SpaceProductsComponent";
import { getLast14days } from "./../../utils/functions/commonFunctions";
const SpaceDetails = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const dispatch = useAppDispatch();

  let getLabelsforChart = getLast14days();

  return (
    <Box mx={6}>
      <Box mt={-3}>
        <Box sx={{ padding: "40px 0px" }}>
          <Box
            mb={1}
            sx={{ fontSize: fontConst?.FONTSIZE_30, fontWeight: 700 }}
          >
            Space Details
          </Box>
          <Breadcrumbs aria-label="breadcrumb" separator={">"}>
            <Link
              to="/dashboard/spaces"
              onClick={() => {
                dispatch(NavigatedFromSpace(false));
              }}
              style={{
                textDecoration: "none",
                color: colorConst?.Orange,
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
              Space
            </Link>
            <Link
              onClick={() => {
                dispatch(NavigatedFromSpace(true));
                dispatch(isSpaceState("All Spaces"));
              }}
              to="/dashboard/spaces"
              style={{
                textDecoration: "none",
                color: colorConst?.Orange,
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
              All Space
            </Link>
            <Link
              to={""}
              style={{
                textDecoration: "none",
                color: colorConst.fontBlack,
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
              Space Details
            </Link>
          </Breadcrumbs>
          <Box mt={3}>
            <Grid container spacing={3} columns={32}>
              <Grid item xs={20}>
                <SpaceDetailsComponent
                  onOpen={() => {
                    onOpen();
                  }}
                />
                <Box mr={4} mt={3}>
                  <CustomTable
                    title={"Space Members (7.8k)"}
                    description={"You can see all members of this space."}
                    isType="3"
                    hasSearch={true}
                    userTableData={items7}
                    Users={userActivityheader}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  position: "relative",
                  overflow: "visible",
                  zIndex: 9,
                  width: "100%",
                }}
              >
                <Box display={"flex"} flexDirection={"column"} gap={3}>
                  <Box mt={2}>
                    <SpaceProductsComponent />
                  </Box>
                  <Box>
                    <Box mr={4}>
                      <CustomTable
                        isType={"3"}
                        title={"Space Activity"}
                        description={
                          "You can see the all activities of this space"
                        }
                        userTableData={spaceActivity1}
                        Users={spaceActivity}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Card shadow="none" radius="sm">
              <CardBody>
                <RevenueChart
                  YAxis={["0", "4 hr", "9 hr", "14 hr", "19 hr", "24 hr"]}
                  label={getLabelsforChart}
                  title={"Usage Time Statistics"}
                  description={"Past 14 days of usage time per days"}
                  isDisplayRightSide={false}
                />
              </CardBody>
            </Card>
          </Box>
          <Box mt={2} mr={4}>
            <CustomTable
              title={"Invoices"}
              description={"You can see the all invoices"}
              isType="3"
              userTableData={paiUserData}
              Users={paidUser}
            />
          </Box>
        </Box>
      </Box>
      <TnCModal
        onOpenChange={() => {
          onOpenChange();
        }}
        isOpen={isOpen}
      />
    </Box>
  );
};

export default SpaceDetails;
