import React, { useEffect, useState } from "react";
import Navbar from "./../common/Navbar";
import Header from "./../common/Header";
import { disableBrowserBackButton } from "./../../utils/functions/commonFunctions";
import { Outlet, useNavigate } from "react-router-dom";
import supabase from "./../../libs/SupabaseClient";

import { Box } from "@mui/material";
const DashBoard = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  // Call this function to disable the back button behavior
  useEffect(() => {
    disableBrowserBackButton();
  }, []);

  useEffect(() => {
    let timeout: any;
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event: any, session: any) => {
       
        if (localStorage?.getItem("ActiveSession") !== session?.refresh_token) {
          // timeout = setTimeout(() => {
          //   supabase.auth.signOut();
          //   navigate("/");
          // }, 3600 * 1000);
        } else if (event === "USER_UPDATED") {
          supabase.auth.signOut();
          navigate("/");
        } else if (event === "SIGNED_OUT") {
          supabase.auth.signOut();
          navigate("/");
        }
      }
    );
    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        supabase.auth.signOut();
        navigate("/");
      }, 3600* 1000);
    };
    const handleMouseMove = () => {
      resetTimeout();
    };
    const handleKeyPress = () => {
      resetTimeout();
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("keypress", handleKeyPress);
      // clearTimeout(timeout);
      authListener?.subscription?.unsubscribe();
    };
  }, [navigate]);

  return (
    <div>
      <div
        style={{ position: "fixed" }}
        onClick={() => {
          setOpen(false);
        }}
      >
        <Navbar />
      </div>
      <div style={{ margin: "0px 0px 0px 120px", position: "sticky" }}>
        <Header open={open} setOpen={setOpen} />
        <Box
          style={{ background: "#F4F4F4" }}
          mt={2}
          onClick={() => {
            setOpen(false);
          }}
        >
          <Outlet />
        </Box>
      </div>
    </div>
  );
};

export default DashBoard;
