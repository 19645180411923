import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { fakerDE as faker } from "@faker-js/faker";
import "./bar-chart.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export function AreaChat(props: any) {

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,

        text: "Chart.js Line Chart",
      },

      tooltip: {
        displayColors: false,
        backgroundColor: "rgba(31, 13, 4, 1)",
        yPadding: 15,
        xPadding: 45,
        titleAlign: "center",
        footerAlign: "center",
        bodyAlign: "center",

        callbacks: {
          title: (ctx: any) => {
            return props?.YAxis ? null : "Revenue";
          },
          label: (ctx: any) => {
            return props?.YAxis
              ? `${ctx?.parsed?.y} hr`
              : `€ ${ctx?.parsed?.y}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        // stepSize:props?.YAxis && 1,
        display: true,
        position: props?.isDisplayRightSide ? "right" : "left",
        beginAtZero: true,
        min: 0,
        grace: 3,
        border: {
          dash: props?.YAxis ? [0, 0] : [5, 5],
        },
        grid: {
          color: "#d3d3d3",
        },
        max: props?.YAxis && 24,
        ticks: {
          callback: function (value: any, index: any, values: any) {
            return props?.YAxis ? value + "hr" : value / 1000 + "k";
          },
        },
      },
    },
    tooltips: {
      enabled: true,
      position: "nearest",
    },
   
  };
  const data = {
    labels: props?.labels,

    datasets: [
      {
        fill: {
          target: "origin",
          above: "rgba(231, 182, 155, 0.2)", // Area will be semi-transparent red above the origin
        },

        lineTension: 0.5, // Set this to control the curve
        label: "Revenue",
        data: props?.labels.map(() =>
          faker.datatype.number({ min: 0, max: props?.YAxis ? 24 : 10000 })
        ),
        borderColor: "#E7651C",

        pointRadius: 3, // Set this to 0 to remove the points
        pointBackgroundColor: "#E7651C", // Color of the points
        pointBorderColor: "#E7651C", // Border color of the points
        pointBorderWidth: 1, // Border width of the points
        backgroundColor: "rgba(231, 101, 28, 0.4)",
        hoverBackgroundColor: "#E7651C", // Hover fill color
        hoverBorderColor: "#E7651C", // Hover border color
        hoverRadius: 6,
        background: (ctx: any) => {
          const gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, "rgba(231, 101, 28, 0.2)");
          gradient.addColorStop(1, "rgba(255, 255, 255, 1)"); // Transparent at the end
          return gradient;
        },
      },
    ],
  };

  return (
    <Line
      className="area-chart"
      options={options}
      data={data}
      style={{ maxHeight: "430px" }}
    />
  );
}
