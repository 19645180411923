import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./../store";
export interface rukkorStore {
  factorId: any;
  isResetPassword: any;
  isNewPassword: any;
  step: any;
  userDetail: any;
  isNavigatedFromUser: any;
  isNavigatedFromSpace: any;
  userState: any;
  spaceState: any;
  userData:any;
  spaceData:any
}
const initialState: rukkorStore = {
  factorId: [],
  isResetPassword: null,
  isNewPassword: null,
  step: null,
  userDetail: null,
  isNavigatedFromUser: null,
  isNavigatedFromSpace: null,
  userState: null,
  spaceState: null,
  userData:null,
  spaceData:null
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    userFactorId: (state, action) => {
      state.factorId = [state.factorId, action.payload];
    },
    resetPassword: (state, action) => {
      state.isResetPassword = action.payload;
    },
    newPassword: (state, action) => {
      state.isNewPassword = action.payload;
    },
    newStep: (state, action) => {
      state.step = action.payload;
    },
    ActiveUser: (state, action) => {
      state.userDetail = action.payload;
    },
    NavigatedFromUser: (state, action) => {
      state.isNavigatedFromUser = action.payload;
    },
    NavigatedFromSpace: (state, action) => {
      state.isNavigatedFromSpace = action.payload;
    },
    isUserState: (state, action) => {
      state.userState = action.payload;
    },
    isSpaceState: (state, action) => {
      state.spaceState = action.payload;
    },
    isUserData: (state, action) => {
      state.userData = action.payload;
    },
    isSpaceData: (state, action) => {
      state.spaceData = action.payload;
    },
  },
});

export const selectFactorId: any = (state: RootState) => state.factorId;
export const selectResetPassword: any = (state: RootState) =>
  state.isResetPassword;
export const selectNewPassword: any = (state: RootState) => state.isNewPassword;
export const selectStep: any = (state: RootState) => state.step;
export const selectedUser: any = (state: RootState) => state.userDetail;
export const selectNavigatedFromUser: any = (state: RootState) =>
  state.isNavigatedFromUser;
export const selectNavigatedFromSpace: any = (state: RootState) =>
  state.isNavigatedFromSpace;
export const selectUserState: any = (state: RootState) => state.userState;
export const selectSpaceState: any = (state: RootState) => state.spaceState;
export const selectUserData: any = (state: RootState) => state.userData;
export const selectSpaceData: any = (state: RootState) => state.spaceData;

export const {
  userFactorId,
  resetPassword,
  newPassword,
  newStep,
  ActiveUser,
  NavigatedFromUser,
  NavigatedFromSpace,
  isUserState,
  isSpaceState,isUserData,isSpaceData
} = adminSlice.actions;

export default adminSlice.reducer;
