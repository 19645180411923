import React from "react";
import { Box, Grid } from "@mui/material";

import { Card } from "@nextui-org/react";
import colorConst from "./../../../Constants/ColorConstant";
import fontConst from "./../../../Constants/FontConstant";

const UserDetailComponent = () => {
  return (
    <Box>
      <Grid container columns={25} columnSpacing={2} rowSpacing={2}>
        <Grid item xs={5}>
          <Card
            radius="sm"
            style={{
              borderRadius: "14px",
              background: "transparent",
              border: `1px dashed ${colorConst.dashedBorder}`,
              padding: "12px",
              display: "flex",
              flexDirection: "row",

              gap: "10px",
            }}
            shadow="none"
          >
            <Box
              gap={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "220px",
              }}
            >
              <div
                style={{
                  color: colorConst.fontBlack,
                  fontWeight: fontConst.FONTWEIGHT_400,
                  fontSize: fontConst.FONTSIZE_14,
                }}
              >
                {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                info@rukkor.com
              </div>
              <div
                style={{
                  color: colorConst.fontGray,
                  fontWeight: fontConst.FONTWEIGHT_400,
                  fontSize: fontConst.FONTSIZE_14,
                }}
              >
                Email
              </div>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={5}>
          <Card
            radius="sm"
            style={{
              borderRadius: "14px",
              background: "transparent",
              border: `1px dashed ${colorConst.dashedBorder}`,
              padding: "12px",
              display: "flex",
              flexDirection: "row",

              gap: "10px",
            }}
            shadow="none"
          >
            <Box
              gap={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "220px",
              }}
            >
              <div
                style={{
                  color: colorConst.fontBlack,
                  fontWeight: fontConst.FONTWEIGHT_400,
                  fontSize: fontConst.FONTSIZE_14,
                }}
              >
                {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                +90 000 0000 000{" "}
              </div>
              <div
                style={{
                  color: colorConst.fontGray,
                  fontWeight: fontConst.FONTWEIGHT_400,
                  fontSize: fontConst.FONTSIZE_14,
                }}
              >
                Mobile Number
              </div>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={5}>
          <Card
            radius="sm"
            style={{
              borderRadius: "14px",
              background: "transparent",
              border: `1px dashed ${colorConst.dashedBorder}`,
              padding: "12px",
              display: "flex",
              flexDirection: "row",

              gap: "10px",
            }}
            shadow="none"
          >
            <Box
              gap={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "220px",
              }}
            >
              <div
                style={{
                  color: colorConst.fontBlack,
                  fontWeight: fontConst.FONTWEIGHT_400,
                  fontSize: fontConst.FONTSIZE_14,
                }}
              >
                {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                25th October 2000{" "}
              </div>
              <div
                style={{
                  color: colorConst.fontGray,
                  fontWeight: fontConst.FONTWEIGHT_400,
                  fontSize: fontConst.FONTSIZE_14,
                }}
              >
                Birth Date
              </div>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={5}>
          <Card
            radius="sm"
            style={{
              borderRadius: "14px",
              background: "transparent",
              border: `1px dashed ${colorConst.dashedBorder}`,
              padding: "12px",
              display: "flex",
              flexDirection: "row",

              gap: "10px",
            }}
            shadow="none"
          >
            <Box
              gap={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "220px",
              }}
            >
              <div
                style={{
                  color: colorConst.fontBlack,
                  fontWeight: fontConst.FONTWEIGHT_400,
                  fontSize: fontConst.FONTSIZE_14,
                }}
              >
                {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                Dignizant Technology
              </div>
              <div
                style={{
                  color: colorConst.fontGray,
                  fontWeight: fontConst.FONTWEIGHT_400,
                  fontSize: fontConst.FONTSIZE_14,
                }}
              >
                Work Name
              </div>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={5}>
          <Card
            radius="sm"
            style={{
              borderRadius: "14px",
              background: "transparent",
              border: `1px dashed ${colorConst.dashedBorder}`,
              padding: "12px",
              display: "flex",
              flexDirection: "row",

              gap: "10px",
            }}
            shadow="none"
          >
            <Box
              gap={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "220px",
              }}
            >
              <div
                style={{
                  color: colorConst.fontBlack,
                  fontWeight: fontConst.FONTWEIGHT_400,
                  fontSize: fontConst.FONTSIZE_14,
                }}
              >
                {/* {`${userDetails[0]?.first_name} ${userDetails[0]?.last_name}`} */}
                UI/UX Designer{" "}
              </div>
              <div
                style={{
                  color: colorConst.fontGray,
                  fontWeight: fontConst.FONTWEIGHT_400,
                  fontSize: fontConst.FONTSIZE_14,
                }}
              >
                Title
              </div>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={5}>
          <Card
            radius="sm"
            style={{
              borderRadius: "14px",
              background: "transparent",
              border: `1px dashed ${colorConst.dashedBorder}`,
              padding: "12px",
              display: "flex",
              flexDirection: "row",

              gap: "10px",
            }}
            shadow="none"
          >
            <Box
              gap={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "220px",
              }}
            >
              <div
                style={{
                  color: colorConst.fontBlack,
                  fontWeight: fontConst.FONTWEIGHT_400,
                  fontSize: fontConst.FONTSIZE_14,
                }}
              >
                11th October 2023
              </div>
              <div
                style={{
                  color: colorConst.fontGray,
                  fontWeight: fontConst.FONTWEIGHT_400,
                  fontSize: fontConst.FONTSIZE_14,
                }}
              >
                Registration Date
              </div>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetailComponent;
