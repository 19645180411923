import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import ImageConst from "./../../Constants/ImageConst";
import ColorConstant from "./../../Constants//ColorConstant";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "./../../store/hooks";
import {
  items,
  items3,
  items4,
  items5,
  items6,
} from "./../../static/tableData";

import {
  NavigatedFromUser,
  NavigatedFromSpace,
  isUserState,
  isSpaceState,
  isUserData,
  isSpaceData,
} from "./../../store/slices/adminSlice";
import Statistics from "./../common/Statistics";
import RevenueChart from "../charts/RevenueChart";
import SpaceChart from "../charts/SpaceChart";
import CustomTable from "./../common/CustomTable";
import {
  newlyCreatedSpaces,
  recentUsers,
  invoices,
  unpaidInvoices,
} from "./../dashboard/tables/TableHeader";
const UserDashBoard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigation = (props: any) => {
    props === "users"
      ? dispatch(NavigatedFromUser(true))
      : dispatch(NavigatedFromSpace(true));
    navigate(`${props}`);
  };
  useEffect(() => {
    dispatch(NavigatedFromUser(null));
    dispatch(NavigatedFromSpace(null));
  }, [dispatch]);
  const handleClick: any = (prop: any) => {
    navigate("/dashboard/spaces/space-details");
  };

  const handleClickforUser: any = (prop: any) => {
    navigate("/dashboard/users/user-details");
  };

  return (
    <Box mx={6}>
      <Box>
        <Grid container spacing={3} columns={32}>
          <Grid item xs={8}>
            <Box
              onClick={() => {
                handleNavigation("spaces");
                dispatch(isSpaceState("All Space"));
                dispatch(isSpaceData(items3));
              }}
            >
              <Statistics
                title={"All Space"}
                count={900}
                icon={ImageConst?.space_icon}
                color={ColorConstant?.lightBlue}
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              onClick={() => {
                handleNavigation("users");
                dispatch(isUserState("All Users"));
                dispatch(isUserData(items));
              }}
            >
              <Statistics
                title={"All Users"}
                count={"3.5k"}
                icon={ImageConst?.user_icon}
                color={ColorConstant?.lightGreen}
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box>
              <Statistics
                customStyle={{ cursor: "default !important" }}
                title={"Total Revenue Space"}
                count={326}
                icon={ImageConst?.total_revenue_icon}
                color={ColorConstant?.lightPink}
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box>
              <Statistics
                customStyle={{ cursor: "default !important" }}
                title={"Total Revenue"}
                count={"€ 53k"}
                icon={ImageConst?.revenue_icon}
                color={ColorConstant?.lightBrown}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Box mt={3} mr={4}>
          <Grid container spacing={3} columns={32}>
            <Grid item xs={21.5}>
              <Box display={"flex"} flexDirection={"column"} gap={3}>
                <Box>
                  <SpaceChart />
                </Box>
                <Box>
                  <RevenueChart
                    title={"Revenue"}
                    description={"This is the all about new registered users"}
                    isDisplayRightSide={false}
                  />
                </Box>
                <Box sx={{ borderRadius: "6px" }}>
                  <Grid
                    container
                    rowSpacing={3}
                    columns={22}
                    columnSpacing={{ xs: 1, sm: 2, md: 5, lg: 7 }}
                  >
                    <Grid
                      item
                      xs={11}
                      sm={10}
                      md={10}
                      lg={10.2}
                      xl={10.3}
                      style={{ borderRadius: "6px" }}
                    >
                      <CustomTable
                        title={"Paid invoices"}
                        description={
                          "You can see the list of paid space and users"
                        }
                        hasSearch={true}

                        isType="3"
                        userTableData={items6}
                        Users={invoices}
                      />
                    </Grid>
                    <Grid item xs={11} sm={10.7} md={10.7} lg={10.9} xl={11}>
                      <Box>
                        <CustomTable
                          title={"Unpaid invoices"}
                          description={
                            "You can see the list of paid space and users" }
                          isType="3"
                          userTableData={items6}
                          hasSearch={true}
                          Users={unpaidInvoices}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={10.5}>
              <Box display={"flex"} flexDirection={"column"} gap={3}>
                <CustomTable
                  title={"Newly created Spaces"}
                  isType="2"
                  userTableData={items4}
                  Users={newlyCreatedSpaces}
                  onClick={handleClick}
                />

                <CustomTable
                  title={"Newly created User"}
                  isType="2"
                  userTableData={items5}
                  Users={recentUsers}
                  onClick={handleClickforUser}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default UserDashBoard;
