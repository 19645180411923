import React, { useState } from "react";
import colorConst from "./../../Constants/ColorConstant";
import { Card, CardHeader, CardBody, Tabs, Tab } from "@nextui-org/react";
import { Box, Typography } from "@mui/material";
import { BarChart } from "./BarChart";
import fontConst from "./../../Constants/FontConstant";
const RevenueChart = (props: any) => {
  const [selected, setSelected] = useState<any>("Annually");
  const Annually = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUNE",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const Weekly = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  // const Monthly = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

  function getArrayFromDate(date: any) {
    const dateArray = [];
    for (let i = 1; i <= date; i++) {
      dateArray.push(i);
    }
    return dateArray;
  }

  const now = new Date();
  const currentDate = now.getDate();
  const Monthly = getArrayFromDate(currentDate);
  // Usage example:
  const handleColor = (prop: any) => {
    setSelected(prop);
  };

  const Filters = ["Weekly", "Monthly", "Annually"];
  return (
    <Box>
      <Card
        shadow="none"
        style={{ borderRadius: "6px", padding: "16px 16px 16px 16px" }}
      >
        {/* //className="max-w-[400px] p-3" */}
        <CardHeader className="flex justify-between box-border gap-5">
          <Box>
            <Typography
              sx={{ fontSize: fontConst.FONTSIZE_20, fontWeight: "700" }}
            >
              Space Overview
            </Typography>
            <Box
              mt={1}
              style={{ color: "#999999", fontSize: "14px", fontWeight: 400 }}
            >
              This is all about the new created space.
            </Box>
          </Box>

          {!props?.isLogin && (
            <Tabs
              key={"bordered"}
              // variant={"solid"}
              classNames={{
                tabList: "gap-6  p-2 ",
                cursor: "bg-[#E7651C] ",
                // tab: "max-h-[400px]"
                // tab: "px-3 text-white",
              }}
              aria-label="Tabs variants"
              selectedKey={selected}
              onSelectionChange={(e: any) => {
                handleColor(e);
              }}
              style={{
                borderRadius: "6px",
                background: "transparent",
                height: "53px",
              }}
            >
              {Filters?.map((user: any) => {
                return (
                  <Tab
                    key={user}
                    title={
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: "6px",
                          // padding:"8px 0px",
                          fontWeight: 400,
                          fontSize: "15px",
                          fontFamily: "roboto",

                          color:
                            user === selected
                              ? "white"
                              : colorConst.fontBlackMini,
                          alignItems: "center",
                        }}
                      >
                        {user}
                      </Box>
                    }
                    style={{
                      borderRadius: "12px",
                      height: "39px",
                      color: user === selected ? "white" : "black",
                      fontSize: "15px",
                      fontWeight: 500,
                      fontFamily: "roboto",
                      // height: "54px",
                      background:
                        user === selected ? colorConst.Orange : "transparent",
                      // borderRadius: "14px 14px 0px 0px",
                      border: "1px solid transparent",
                    }}
                  />
                );
              })}
            </Tabs>
          )}
        </CardHeader>
        <CardBody>
          <BarChart
            labels={
              selected === "Annually"
                ? Annually
                : selected === "Monthly"
                ? Monthly
                : Weekly
            }
          />
        </CardBody>
      </Card>
    </Box>
  );
};

export default RevenueChart;
