export const userTableHeader=["user", "email", "mobile number", "activity", "date & time", "category"];
export const spaceTableHeader=["space","space type","total member","admin"," created date & time","revenue"]
export const newlyCreatedSpaces=["spacename", "spaceCreatedAt"]
export const recentUsers=["username", "userCreatedAt"]
export const spaceActivity=["username","activity", "Date & time"]

export const invoices=["user/space", "Paid invoice Date","Amount"]  
export const unpaidInvoices=["user/space", "Due invoice Date","Amount"]  
export const userActivityheader=["user", "email", "activity", "date & time"];
export const spacesList=["space", "created date & time"]
export const topSpaces=["space", "spaceDetails"]
export const userActivity= ["date", "login time", "logout time", "total time", "device"]
export const paidUser= ["amount", "Invoice number", "Invoice date", "status", " action"]