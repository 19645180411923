import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import ImageConst from "./../../Constants/ImageConst";
import ColorConstant from "./../../Constants//ColorConstant";
import Statistics from "./../common/Statistics";
import { useAppSelector } from "./../../store/hooks";
import AllSpacesTable from "./tables/AllSpacesTable";
import { useAppDispatch } from "./../../store/hooks";
import CustomTable from "./../common/CustomTable";
import {spacesList, topSpaces} from "./tables/TableHeader";
import { items8,items9 } from "./../../static/tableData";
import SpaceChart from "./../charts/SpaceChart";

import {
  NavigatedFromUser,
  NavigatedFromSpace,
  selectNavigatedFromSpace,
  isSpaceState,
} from "./../../store/slices/adminSlice";
import LoginActivityChart from "../charts/LoginActivityChart";
import { useNavigate } from "react-router-dom";

const Spaces = () => {
  const isNavigatredfromDashboard: any = useAppSelector(
    selectNavigatedFromSpace
  );
  const dispatch = useAppDispatch();
const navigate= useNavigate()
  const handleClick:any=(prop:any)=>{
    navigate('/dashboard/spaces/space-details')
    
    }

  useEffect(() => {
    dispatch(NavigatedFromUser(null));
  }, [dispatch]);
  return (
    <Box  mx={6}>
    
      {isNavigatredfromDashboard === true ? (
          <Box mt={-3}>
        
            <AllSpacesTable />
          </Box>
      ) : (
        <Box mt={2}>
          <Grid container spacing={3} columns={32}>
            <Grid
              item
              xs={8}
              onClick={() => {
                dispatch(isSpaceState("All Space"));
                dispatch(NavigatedFromSpace(true));
              }}
            >
              <Box>
                <Statistics
                  title={"All Space"}
                  count={900}
                  icon={ImageConst?.space_blue}
                  color={ColorConstant?.lightBlue}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              onClick={() => {
                dispatch(isSpaceState("Work Space"));
                dispatch(NavigatedFromSpace(true));
              }}
            >
              <Box>
                <Statistics
                  title={"Work Space"}
                  count={900}
                  icon={ImageConst?.space_brown}
                  color={ColorConstant?.lightBrown}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              onClick={() => {
                dispatch(isSpaceState("Community Space"));
                dispatch(NavigatedFromSpace(true));
              }}
            >
              <Box>
                <Statistics
                  title={"Community Space"}
                  count={900}
                  icon={ImageConst?.space_green}
                  color={ColorConstant?.lightGreen}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              onClick={() => {
                dispatch(isSpaceState("Archived Space"));
                dispatch(NavigatedFromSpace(true));
              }}
            >
              <Box>
                <Statistics
                  title={"Archived Space"}
                  count={900}
                  icon={ImageConst?.space_gray}
                  color={ColorConstant?.lightGray}
                />
              </Box>
            </Grid>
          </Grid>
          <Box>
           <Box mt={3} mr={4}>
             <Grid container spacing={3} columns={32}>
               <Grid item xs={21.5}>
                 <Box display={"flex"} flexDirection={"column"} gap={3}>
                   <Box>
                     <SpaceChart />
                   </Box>
                 
                   <Box>
                     <Grid
                       container
                       rowSpacing={3}
                       columns={22}
                       columnSpacing={{ xs: 1, sm: 2, md: 5, lg: 7 }}
                     >
                       <Grid item xs={11} sm={10} md={10} lg={10.2} xl={10.3}>
                         <Box>
                          
                           <CustomTable
                             title={"Newly created Spaces"}
                            
                             isType="2"
                             userTableData={items8}
                             Users={spacesList}
                             onClick={handleClick}
                           />
                         </Box>
                       </Grid>
                       <Grid item xs={11} sm={10.7} md={10.7} lg={10.9} xl={11}>
                         <Box>
                          
                           <CustomTable
                             title={"Top Spaces"}
                            
                             isType="2"
                             userTableData={items9}
                             Users={topSpaces}
                             onClick={handleClick}

                           />
                         </Box>
                       </Grid>
                     </Grid>
                   </Box>
                 </Box>
               </Grid>
               <Grid item xs={10.5}>
                <Box mr={-3.7}> 
               <LoginActivityChart  title={"Space Usage Activity"}  description={"Total working space on each days"} />

                </Box>

               </Grid>
             </Grid>
           </Box>
         </Box>
        </Box>
          
      )}
    </Box>
  );
};

export default Spaces;
