export const terms = [
  `Respect others:Treat others with kindness,
respect, and consideration, regardless of their age, race,
gender, sexual orientation, religion, or any other personal
characteristic.`,
  `No harassment: Harassment of any kind will
not be tolerated, including but not limited to verbal,
physical, and sexual harassment. This includes using
derogatory language, making threats, or engaging in unwanted
advances.`,
  `No hate speech: Hate speech, discrimination, and
intolerance will not be tolerated. This includes making
derogatory comments about race, ethnicity, religion, gender,
or sexual orientation.`,
  `No spamming: Do not spam the chat with
irrelevant or excessive messages. This includes posting links
to unrelated content or promoting products or services without
permission.`,
  `.No illegal activity: Do not engage in any illegal
activity, including sharing or distributing illegal content,
soliciting or engaging in illegal activities, or infringing on
the intellectual property rights of others.`,
  `No impersonation:Do not impersonate others, including other members of the chat
community or individuals outside the community.`,
  ` Follow instructions: Follow the instructions of moderators and
administrators. Failure to do so may result in disciplinary
action, including temporary or permanent removal from the chat
community.`,
];
